import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import i18next from "i18next";
import './footer.css';
class index extends Component<{ history?: any }, {}> {
  constructor(props: any) {
    super(props);
  }
  render() {
    let date = new Date()
    let path = window.location.pathname;
    return (
      <footer className="footer bg-dark" style={{ marginTop: path == '/home' ? '4.8rem' : "unset" }}>
        <div className="footer-middle pt-5 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6 mr-5">
                <div className="widget">
                  <h4 className="widget-title">{i18next.t('footer.customer_service')}</h4>
                  <div className="d-flex justify-content-between">
                    <ul className="links ">
                    <li><Link to={{pathname: "/locations-overview", search: window.location.search}}>{i18next.t('footer.locations')}</Link></li>
                      {/* <li><Link to={{pathname: "/terms-and-conditions", search: window.location.search}}>{i18next.t('footer.term_conditions')}</Link></li> */}
                      {/* <li><Link to={{pathname: "/feedback", search: window.location.search}}>{i18next.t('footer.contact_us')}</Link></li> */}
                      {/* <li><Link to={{pathname: "/our-story", search: window.location.search}}>{i18next.t('footer.our_story')}</Link></li> */}
                      {/* <li><Link to="/privacy-policy">{i18next.t('footer.privacy_policy')}</Link></li> */}
                    </ul>
                    <ul className="links">
                      {/* <li><Link to={{pathname: "/locations-overview", search: window.location.search}}>{i18next.t('footer.locations')}</Link></li> */}
                      {/* <li><Link to={{pathname: "/nutritional-info", search: window.location.search}}>{i18next.t('footer.nutritional_info')}</Link></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="widget widget-newsletter">
                  <h4 className="widget-title">{i18next.t('footer.subscribe_form')}</h4>
                  <p>{i18next.t('footer.subscribe_text2')}</p>
                  <div className="d-flex justify-content-between">
                  <Link to="/mailing-list"><button className="btn subscribe-btn shadow-none mb-0">{i18next.t('footer.join_mail')} </button></Link>
                  <div className="social col-sm-12 text-right">
                    <a className="facebook-btn"
                      title={i18next.t("footer.facebook")}
                      href="https://www.facebook.com/morecafesarabia" target="_blank">
                      <i className="icon-facebook" ></i></a>
                    <a className="instagram-btn" title={i18next.t("footer.instagram")} href="https://www.instagram.com/morecafearabia/" target="_blank"><i className="icon-instagram"></i></a>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-sm-6 d-flex justify-content-end">
                <div className="widget">
                  {/* <h4 className="widget-title">Contact Info</h4>
                  <ul className="contact-info">
                    <li>
                      <span className="contact-info-label">Address</span>Tortilla, 1st Floor, Evelyn House, 142-144 New Cavendish St, London, W1W 6YF.
                    </li>
                    <li>
                      <span className="contact-info-label">Phone</span><a href="tel:">123123</a>
                    </li>
                    <li>
                      <span className="contact-info-label">Email</span> <a href="mailto:mail@example.com">mail@example.com</a>
                    </li>
                  </ul> */}
                  
                </div>
              </div>

              {/* <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h4 className="widget-title">Popular Tags</h4>

                  <div className="tagcloud">
                    <a href="#">Bag</a>
                    <a href="#">Black</a>
                    <a href="#">Blue</a>
                    <a href="#">Clothes</a>
                    <a href="#">Hub</a>
                    <a href="#">Shirt</a>
                    <a href="#">Shoes</a>
                    <a href="#">Skirt</a>
                    <a href="#">Sports</a>
                    <a href="#">Sweater</a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-bottom border-top-0 footer-mobile d-flex justify-content-between align-items-center flex-wrap p-0">
            <p className="footer-copyright py-3 pr-4 mb-0 text-white">
              More Cafe {date.getFullYear()} {i18next.t('footer.all_rights')}.
            </p>
            <p className="text-light text-center mt-1">
              {i18next.t('footer.powered_by')}{" "}
              <a href="https://simplexts.net/" target="/blank">
                <h6 className="text-white">Simplex Technology Solutions</h6>
              </a>
            </p>
            {/* <img src="assets/images/payments.png" alt="payment methods" className="footer-payments py-3"/> */}
          </div>
          {/* <!-- End .footer-bottom --> */}
        </div>
        {/* <!-- End .container --> */}
      </footer>
    );
  }
}

export default index;
