import React, { useEffect, lazy, Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import TagManager from 'react-gtm-module'

import Main from "./components/Home/mainLogin";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import Cart from "./screens/Cart";
// import CheckoutPage from "./screens/Checkout"
import AccountAuth from './screens/AccountAuth'
import LoyalityAuth from './screens/LoyalityAuth';
import Location from "./screens/Checkout"
import Payment from "./screens/Payment"
import Profile from "./screens/Profile";
import NewForgot from "./screens/OtpPassword";
import RestuarantLoc from "./screens/RestuarantLocator";
import TrackOrder from "./screens/TrackOrder";
import CustomerComplaint from "./screens/CustomerComplaint";
import Aboutus from "./screens/Aboutus";
import Terms from "./screens/Terms"
import Privacy from "./screens/Privacy";
import MailingList from './screens/NewsLetterMails'
import Thank from "./screens/thankyou";
import NutritionalInfo from "./screens/NutritionalInfo";
import Landing from "./screens/Landing"
const Home = lazy(() => import('./screens/Home'));


//GTM for Tortilla Staging
// const tagManagerArgs = {
//   gtmId: 'GTM-PQ3N5RW'
// }

//GTM for Tortilla Live Simplex Project
const tagManagerArgs = {
  gtmId: 'GTM-KQJ8T9P'
}

//GTM for Tortilla eathos marketing agency setuped account
// const tagManagerArgs = {
//   gtmId: 'GTM-5MHSK68'
// }
// background: rgb(64 48 48);
//     background: ;
const Notfound = () => <div className="w-100" style={{minHeight: '1000px', background: "linear-gradient(90deg, rgba(201,168,177,1) 0%, rgba(139,70,89,1) 32%, rgba(73,40,74,1) 71%, rgba(58,35,62,1) 100%)"}}><img style={{width: '300px', margin: "auto", paddingTop: '20rem'}} className="" src={process.env.PUBLIC_URL + "/assets/images/not_found.png"}/></div>

function App() {
  let location = useLocation()

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [location])
  return (
    <Provider store={store}>
      <Suspense fallback={<div></div>}>
        <Switch>
          {/* <Route exact path="/" component={Main} /> */}
          <Route exact path="/" component={LoyalityAuth} />
          <Route exact path="/home" component={Home} />
          {/* <Route exact path="/menu" component={Home} /> */}
          {/* <Route exact path="/menu" render={() => (
            <Redirect to="/home" />
          )} /> */}
          {/* <Route exact path="/login" component={Login} /> */}
          {/* <Route exact path="/cart" component={Cart} /> */}
          <Route exact path="/profile" component={Profile} />
          {/* <Route exact path="/combo/:id/(name)?/:name?" component={ComboDetail} /> */}
          {/* <Route exact path="/item/:id/(name)?/:name?" component={ItemDetail} /> */}
          {/* <Route exact path="/groupitem/(name)?/:name?" component={GroupItem} /> */}
          {/* <Route exact path="/auth" component={AccountAuth} /> */}
          <Route exact path="/loyaltyAuth" component={LoyalityAuth} />
          <Route exact path="/location" component={Location} />
          {/* <Route exact path="/payment" component={Payment} /> */}
          <Route exact path="/locations-overview" component={RestuarantLoc} />
          {/* <Route exact path="/track-order" component={TrackOrder} /> */}
          {/* <Route exact path="/feedback" component={CustomerComplaint} /> */}
          {/* <Route exact path="/reset-password" component={NewForgot} /> */}
          {/* <Route exact path="/our-story" component={Aboutus} />
          <Route exact path="/terms-and-conditions" component={Terms} /> */}
          {/* <Route exact path="/privacy-policy" component={Privacy}/> */}
          <Route exact path="/mailing-list" component={MailingList} />
          {/* <Route exact path="/thank-you" component={Thank} /> */}
          {/* <Route exact path="/nutritional-info" component={NutritionalInfo} /> */}
          <Route path="*" exact component={Notfound} />
        </Switch>
      </Suspense>
    </Provider>
  );
}

export default App;
