import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import CheckoutLoyalityComponent from "../components/Checkout/loyalityAuth";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Checkout extends Component<{history?:any},{}> {
  constructor(props: any) {
    super(props);
  }
  componentDidMount(){
    document.title = "Loyalty Auth - More Cafe";
  }
  render() {
    // if (!localStorage.mainToken) {
    //   return <Redirect to="/" />
    // }
    return (
      <div className="page-wrapper">
        <div id="page-wrap">
          <Header history={this.props.history} />
          <CheckoutLoyalityComponent history={this.props.history} />
          <Footer />
        </div>
        
      </div>
    );
  }
}
export default connect(null, null)(Checkout);
