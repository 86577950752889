import Api from "../../ApiManager/index";
import * as geolib from 'geolib';
import i18next from "i18next";
import {
  SEARCH_RESTUARANTS,
  LOGOUT_CUSTOMER,
  SIGN_UP,
  LOG_IN,
  STORES_LIST,
  MENU_COMBO_LIST,
  MENU_GROUPS_LIST,
  MENU_ITEMS,
  ORDER_LIST,
  GET_CART,
  SAVE_CART,
  STORE_NAME,
  GET_TAX,
  STORE_ID,
  STORE_TYPE,
  ORDER_ID,
  COMBO_DETAIL,
  COMBO_DETAIL_ID,
  ITEM_DETAIL,
  ADDRESS_GUEST,
  ORDER_TYPE,
  SAVE_HOUSEDETAIL,
  SAVE_STREETDETAIL,
  SAVE_ADDRESSTYPE,
  SELECT_STORE_ID,
  ADD_GUEST,
  DELIVERY_FEE,
  SELECT_STORE,
  PAYMENT,
  CUSTOMER_ID,
  FRANCHISE_APPLICATION,
  LOGIN_SIGNUP,
  SIGNUP_k2g,
  TOP_LIST,
  HERO_ITEMS,
  LOG_Cart,
  FORGOT,
  ADDRESS_LIST,
  FAV_MENU_ITEM,
  OFFERS_LIST,
  COUPON_DATA,
  ORDER_PERIOD,
  CHECKOUT_TAB_NAME,
  RESTUARANTS,
  SOUQ_MEDIA_RESTUARANTS,
  ORDER_DATA,
  GET_PROFILE,
  TOP_SELLING_ITEMS,
  HANDLE_PROFILE_INPUT,
  ORDER_DETAIL,
  SAVE_ADDRESS_USER,
  SAVE_STORE_DETAILS,
  PAYMENT_METHOD,
  SUCCESS_FLAG,
  ORDER,
  URL_MATCH_METHOD,
  UPDATE_LSM,
  LSM_ITEMS,
  GET_TAX_CASH,
  GET_TAX_CARD,
  SAVE_TAX,
  SAVE_DISCOUNT,
  NETWORK_STATUS,
  NEW_CUSTOMER_PHONE_URL,
  TOP_DEALS_HOME,
  SWITCH_TAB,
  VALIDATE_OTP,
  SPECIFIC_STORE_ITEMS,
  MENU_GROUPS_LIST_HOME,
  HANDLE_SPECIAL_INPUT,
  MENU_STORE_ID,
  UPDATE_GROUPS_LIST,
  CARD_ORDER_DETAIL,
  LOCATION_FLAG,
  LOCATION_MODAL_FLAG,
  ACCOUNT_AUTH,
  SAVE_ADDRESS,
  STORE_OPEN_FLAG,
  PICKUP_STORES_LIST,
  SAVE_CARD_DETAILS,
  EXTRA_ADDRESS_DETAILS,
  LOYALTY_ORDER_INFO,
  GET_COFFEE_CARD_GROUP,
  GET_SMOOTHIE_CARD_GROUP
} from "./types";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import React from "react";
const lang = localStorage.getItem('lang') || 'en'
toast.configure();

export const saveFCMToken = (fcmToken: any) => {
  return async function (dispatch: any) {
    let fcm: any = {
      token: fcmToken,
      is_active: 1
    }
    Api.post(`/customer/save_fcmToken`, fcm)
      .then(async (response) => {
        if (response.data.success) {
          // await localStorage.setItem('fcm_status', 'true');
          // console.log('token saved')
        }
      })
      .catch((err) => {
        let error;
        if (typeof err.response.data.message === 'string') {
          if (err.response.data.code === 'ER_DUP_ENTRY') {
            error = 'Token Already Exist';
          } else {
            error = err.response.data.message;
          }
        } else if (typeof err.response.data.message === 'object') {
          error = err.response.data.message[0].replace(/_/g, ' ');
        }
        // console.log(error)
      });
  };
};
export const NotificationList = () => {
  return async (dispatch: any) => {
    Api.get('/customer/notifications/')
      .then((response) => {
        if (response.data.success) {
          // dispatch({
          //   type: NOTIFICATIONS,
          //   payload: response.data.successResponse,
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          // dispatch({
          //   type: NOTIFICATIONS,
          //   payload: [],
          // });
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  }
};
export const getProfile = () => {
  return async function (dispatch: any) {
    let token: any = await localStorage.getItem('token');
    let decodedData: any = jwt_decode(token);
    let id: any = decodedData.customer.customer_id;
    Api.get(`/customer/profile/${id}`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        if (response.data.success) {
          console.log("user Action data", response.data.successResponse);
          dispatch({
            type: GET_PROFILE,
            payload: response.data.successResponse
          })
        }
      }).catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          alert(err.message)
        }
      });
  }
}

export const handleProfileInput = (event: { target: { name: any; value: any; }; }) => {
  return {
    type: HANDLE_PROFILE_INPUT,
    input: event.target
  }
}
export const handleSpecialInstruction = (event: { target: { name: any; value: any; }; }) => {
  return {
    type: HANDLE_SPECIAL_INPUT,
    input: event.target
  }
}

export const editProfile = (firstname: any, lastname: any, email: any, phone: any, dob: any, gender: any) => {
  return function (dispatch: any) {
    let token: any = localStorage.token;
    let decodedData: any = jwt_decode(token);
    let id: any = decodedData.customer.customer_id;
    Api.put(`/customer/edit_profile/${id}`, {
      first_name: firstname,
      last_name: lastname,
      login_name: firstname + " " + lastname,
      email_address: email,
      phone_number: phone,
      customer_birthday: dob,
      gender: gender
    }, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        if (response.data.success) {
          let token = response.data.accessToken;
          localStorage.setItem('token', token)
          localStorage.setItem('phoneNumber', phone)
          // dispatch(logoutAdmin());
          toast.error(response.data.successResponse, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
          setTimeout(() => {
            window.location.reload()
          }, 1500);
        }
      }).catch(err => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            if (err.response.data.code === 'ER_DUP_ENTRY') {
              error = 'Phone number already exist';
              // toast.warning(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
            } else {
              error = err.response.data.message;
            }
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.warning(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
        } else {
          toast.warning(err.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
        }
      });
  }
}
export const changePassword = (newPass: any) => {
  return function (dispatch: any) {
    let id: any = localStorage.getItem('id');
    Api.put(`/customer/change_password`, {
      customer_id: id,
      login_password: newPass
    }, {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    })
      .then((response) => {
        if (response.data) {
          toast.error("Password changed successfully", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
          dispatch(logoutCustomer());
        }
      }).catch(err => {
        if (err.response) {
          console.log(err.response.data.message)
        } else {
          alert(err.message)
        }
      });
  }
}
export const logink = (data: any, history?: any) => {
  return function (dispatch: any) {
    console.log("email and pass", data)
    let obj = {}
    if (data.email) {
      var objEmail = {
        email: data.email,
        password: data.password
      }
      obj = objEmail
    }
    else if (data.phone_number) {
      var objPhone = {
        phone_number: data.phone_number,
        password: data.password
      }
      obj = objPhone
    }
    Api.post("/customer/auth", obj)
      .then(async (response) => {
        if (response.data.success) {
          let token = response.data.accessToken;
          dispatch({
            type: LOG_IN,
            isLoggedInError: false,
          });
          console.log(response.data.customer)
          await localStorage.setItem("token", token);
          await localStorage.setItem(
            "profile_pic",
            response.data.customer.profile_pic
          );
          await localStorage.setItem(
            "name",
            response.data.customer.login_name
          );
          await localStorage.setItem(
            "email",
            response.data.customer.email_address
          );
          await localStorage.setItem(
            "phone",
            response.data.customer.phone_number
          );
          await localStorage.setItem(
            "id",
            response.data.customer.customer_id
          );
          await localStorage.setItem("gender", response.data.customer.gender);
          await localStorage.setItem(
            "customer_birthday",
            response.data.customer.customer_birthday
          );
          await localStorage.setItem("profiledata", response.data.customer);
          // history.goBack()
          window.location.href = "/"
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: LOG_IN,
            isLoggedInError: true,
            loginErrMessage: lang == 'ar' ? "تفاصيل تسجيل الدخول غير صحيحة" : err.response.data.message
          });
          // console.log("no");
        } else {
          // console.log("yes");
        }
      });
  };
};
// after login addres get of user
export const addressesListk = () => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem("token");
    if (token) {
      var decoded: any = jwt_decode(token);
      Api.get(`/customer/address/${decoded.customer.customer_id}`, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: ADDRESS_LIST,
              payload: response.data.successResponse.length> 0 ? response.data.successResponse : [],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            dispatch({
              type: ADDRESS_LIST,
              payload: [],
            });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};

// login for k2g
export const loginCustomer = (phone_number: any, password: any) => {
  return function (dispatch: any) {
    Api.post("/customer/auth", {
      phone_number: phone_number,
      password: password,
    })
      .then(async (response) => {
        if (response.data.success) {
          let token = response.data.accessToken;
          dispatch({
            type: LOG_IN,
            isLoggedInError: false,
          });
          console.log("profileInfo", response.data.customer);

          await localStorage.setItem("token", token);
          await localStorage.setItem(
            "profile_pic",
            response.data.customer.profile_pic
          );
          await localStorage.setItem(
            "name",
            response.data.customer.login_name
          );
          await localStorage.setItem(
            "email",
            response.data.customer.email_address
          );
          await localStorage.setItem(
            "phone",
            response.data.customer.phone_number
          );
          await localStorage.setItem("gender", response.data.customer.gender);
          await localStorage.setItem(
            "customer_birthday",
            response.data.customer.customer_birthday
          );
          await localStorage.setItem("city", response.data.customer.city);
          await localStorage.setItem(
            "country",
            response.data.customer.country
          );
          await localStorage.setItem(
            "id",
            response.data.customer.customer_id
          );
          // window.location.href = "/";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: LOG_IN,
            isLoggedInError: true,
          });
          // console.log("no");
        } else {
          // console.log("yes");
        }
      });
  };
};
//Logout session out

export const logoutCustomer = () => {
  return async (dispatch: any) => {
    await localStorage.removeItem("token");
    await localStorage.removeItem("profile_pic");
    await localStorage.removeItem("profile_pic_social");
    await localStorage.removeItem("fileUri");
    await localStorage.removeItem("gender");
    await localStorage.removeItem("id");
    await localStorage.removeItem("profiledata");
    await localStorage.removeItem("email");
    await localStorage.removeItem("name");
    await localStorage.removeItem("customer_birthday");
    await localStorage.removeItem("phone");
    await localStorage.removeItem("mainToken");
    await localStorage.removeItem("phoneNumber");
    await localStorage.removeItem("orderId");
   


    //   const _isSignedIn = async () => {
    //     const isSignedIn = await GoogleSignin.isSignedIn();
    //     if (isSignedIn) {
    //       GoogleSignin.revokeAccess();
    //       //revoke Access method is used before the Signout method because SignIn Required for revokeAccess Method
    //       GoogleSignin.signOut();
    //     }
    //   };
    //   _isSignedIn();
    //   LoginManager.logOut();
    //   Toast.show({
    //     text: 'You have been logged out successfully',
    //     duration: 5000,
    //   });
    dispatch({
      type: LOGOUT_CUSTOMER,
      isLoggedIn: false,
    });
    window.location.href = "/";
  };
};

//signUp

export const signupCustomer = (data: any, history?: any, params?: any, latertime?: any) => {
  console.log("signupData", data);
  return function (dispatch: any) {
    try {
      Api.post(`/customer/signup`, data)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            // dispatch(setTabName('payment'))
            setTimeout(() => {
              dispatch({
                type: SIGN_UP,
                isOpen: true,
                SuccessStatus: "successregister",
                // loginCustomer(responseData.email_address, data.login_password),
              });
              dispatch(setTabName('payment'))
              let obj = {
                laterdatetime: latertime
              }
              params.data.latertime = latertime
              history.push({pathname:'/payment', search: window.location.search}, params)
              dispatch(loginCustomer(responseData.phone_number, data.login_password))

            }, 3000);
            dispatch(customerId(responseData.customer_id));
            localStorage.setItem('guestphone', data.phone_number)
            toast.success("Your login credentials has been sent to your email address.")

            // console.log("Customer Registered Successfully for cart", responseData, 'passworddata', data);

          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              if (err.response.data.code === "ER_DUP_ENTRY") {
                dispatch({
                  type: SIGN_UP,
                  emailExit: true,
                });
                // alert("Email Already Exist");
                // error = "Email Already Exist";
                toast.error("Phone Number Already Exist")
              } else {
                toast.error(err.response.data.message);
              }
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            // console.log("yesSignUp");
          } else {
            // console.log("NoSignUp");
          }
        });
    } catch (e) {
      // console.log("NoSignUp", e);
    }
  };
};
export const CreateAccount = (data: any, history: any) => {
  return function (dispatch: any) {
    try {
      Api.post(`/customer/create_account`, data)
        .then(async (response) => {
          if (response.data.success) {
            let token = response.data.accessToken;
            await localStorage.setItem("token", token);

            if(data && data.loyality){
              history.push({pathname: "/profile", search: window.location.search} );
              dispatch(setTabName('profile'))
            }else{
              history.push({pathname: "/location", search: window.location.search})
              dispatch(setTabName('location'))
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 409) {
              toast.error("Your phone number already exist", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
            }
          } else {
            console.log("Customer Registered ", err.response);
          }
        });
    } catch (e) {
      // console.log("NoSignUp", e);
    }
  };
}
// k2g signup

export const signupk = (data: any) => {
  console.log("signupData", data);
  return function (dispatch: any) {
    try {
      Api.post(`/customer/signup`, data)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            setTimeout(() => {
              dispatch({
                type: SIGNUP_k2g,
                isOpen: true,
                successStatusRegister: "successregister",

                // loginCustomer(responseData.email_address, data.login_password),
              });
            }, 1000);
            dispatch(customerId(responseData.customer_id));
            console.log("Customer Registered Successfully", responseData, 'passworddata', data);
            dispatch(
              loginSignup(
                response.data.successResponse.phone_number,
                data.login_password
              )
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              if (err.response.data.code === "ER_DUP_ENTRY") {
                dispatch({
                  type: SIGN_UP,
                  emailExit: true,
                });
                // alert("Email Already Exist");
                // error = "Email Already Exist";
              } else {
                error = err.response.data.message;
              }
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            console.log(err.response.status);
            if (err.response.status === 409) {
              toast.error("Your phone number already exist");
            }
          } else {
            console.log("Customer Registered ", err.response);
          }
        });
    } catch (e) {
      // console.log("NoSignUp", e);
    }
  };
};

// save after register
export const loginSignup = (phone: any, password: any) => {
  console.log('loginsignup', phone, password)
  let data = {
    phone_number: phone,
    password: password
  }
  return function (dispatch: any) {
    dispatch(logink(data));
  };
};
// save customer id
export const customerId = (customerId: any) => {
  console.log("customerId", customerId);
  return function (dispatch: any) {
    dispatch({
      type: CUSTOMER_ID,
      CustomerId: customerId,
    });
  };
};


// Save customer tax value
export const SaveTaxValue = (taxAmount: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_TAX,
      taxAmount: taxAmount,
    });
  };
};

// Save customer totalDiscount
export const SaveDiscountValue = (discountValue: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_DISCOUNT,
      discountValue: discountValue,
    });
  };
};
// Save Rating Recviews

export const saveReview = (orderId: any, data: any, navigation: any) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    console.log("gfhgfghfghfhgf", orderId, data)

    Api.put(`/customer/save_review/${orderId}`, data, {
      headers: { Authorization: 'Bearer ' + token },
    })
      .then((response) => {

        if (response.data.success) {
          navigation.goBack();
          // setImmediate(() => {
          //   Actions.refresh({ key: Math.random() }); //It is used to refesh the page after pop the screen
          // });
          toast.success({
            text: "Thanks for your feedback",
            buttonText: 'OK'
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          toast.info({
            text: error,
            buttonText: 'OK',
            duration: 5000,
            type: 'danger',
          });
        } else {
          toast.info({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};




//guest add for k
export const addGuest = (data: any, history?: any, params?: any, latertime?: any) => {
  console.log(latertime, "000000", params)
  return function (dispatch: any) {
    Api.post(`/customer/addGuest`, data)
      .then(async (response) => {
        if (response.data.success) {
          window.scrollTo(0, 0);
          let obj = {
            laterdatetime: latertime
          }
          params.data.latertime = latertime
          history.push({pathname:'/payment', search: window.location.search}, params)
          dispatch(setTabName('payment'))

          dispatch({
            type: ADD_GUEST,
            payload: response.data.successResponse,
            SuccessStatus: "successguest",
          });

          await localStorage.setItem(
            "guestname",
            response.data.successResponse.login_name
          );
          await localStorage.setItem(
            "guestemail",
            response.data.successResponse.email_address
          );

          await localStorage.setItem(
            "guestphone",
            data.phone_number
          )
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === 'ER_DUP_ENTRY') {
              if (err.response.data.message.indexOf("phone_number") > -1) {
                error = 'Phone Number Already Exist';
              } else {
                error = 'Email Already Exist';
              }
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
        } else {
        }
      });
  };
};

// pickup store

export const findPickupStores = (lat: any, lng: any, searchString: any) => {
  console.log("in pickup store action")
  return async (dispatch: any) => {
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          let activeStores: any = [];
          Responedata.map((store: any) => {
            if (store.is_active == 1 && store.cloudKitchen == 'Disabled') {
              activeStores.push(store);
            }
          })
          const filteredStores = activeStores.filter((store: any) => {
            return (
              store.address
                .toLowerCase()
                .includes(searchString.toLowerCase()) ||
              store.city.toLowerCase().includes(searchString.toLowerCase()) ||
              store.store_name
                .toLowerCase()
                .includes(searchString.toLowerCase())
            );
          });
          if (filteredStores.length == 0) {
            for (let i = 0; i < activeStores.length; i++) {
              if (activeStores[i].zone_json) {
                if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(activeStores[i].zone_json))) {
                  data.push(activeStores[i]);
                }
              }
            }
            if (data.length == 0) {
              dispatch({
                type: STORES_LIST,
                payload: [],
                selectStoreId: "",
                deliveryfee: 0,
                storeStatus: 0,
                lat: lat,
                lng: lng
              });
            } else {
              dispatch({
                type: STORES_LIST,
                payload: data,
                selectStoreId: data[0].store_id,
                deliveryfee: 0,
                storeStatus: 0, // 1 for out of Range,
                lat: lat,
                lng: lng
              });
              dispatch(saveTaxDetails(data[0].state_id.state_name))
              if(data[0].isDays == 0) {
                dispatch(compareStoreTiming(data[0]))
              }
              else {
                dispatch(compareTimingbyEachDay(data[0]))
              }
            }
            // Actions.selectstore();
          } else {
            dispatch({
              type: STORES_LIST,
              payload: filteredStores,
              selectStoreId: filteredStores[0].store_id,
              deliveryfee: 0,
              storeStatus: 0, // 1 for out of Range,
              lat: lat,
              lng: lng
            });
            dispatch(saveTaxDetails(filteredStores[0].state_id.state_name))
            if(filteredStores[0].isDays == 0) {
              dispatch(compareStoreTiming(filteredStores[0]))
            }
            else {
              dispatch(compareTimingbyEachDay(filteredStores[0]))
            }
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // Actions.selectstore();
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};

export const PickupStoresList = (lat: any, lng: any, searchString: any) => {
  console.log("pickup stores list action")
  return async (dispatch: any) => {
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          let activeStores: any = [];
          Responedata.map((store: any) => {
            if (store.is_active == 1 && store.cloudKitchen == 'Disabled') {
              store.value = store.store_id;
              store.label = store.store_name;
              activeStores.push(store);
            }
          })
          data = activeStores;
          if (data.length == 0) {
            dispatch({
              type: PICKUP_STORES_LIST,
              payload: [],
              selectStoreId: "",
              deliveryfee: 0,
              storeStatus: 0,
              lat: lat,
              lng: lng
            });
          } else {
            dispatch({
              type: PICKUP_STORES_LIST,
              payload: data,
              selectStoreId: "",
              deliveryfee: 0,
              storeStatus: 0, // 1 for out of Range,
              lat: lat,
              lng: lng
            });
            dispatch(saveTaxDetails(data[0].state_id.state_name))
            // let result = compareStoreTiming(data[0])
            // dispatch({
            //   type: STORE_OPEN_FLAG,
            //   openFlag: result
            // });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // Actions.selectstore();
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};


/// delivery store

//LocalStore with kml Coordinates Json
export const findLocalStores = (lat: any, lng: any) => {
  console.log("herre")
  return async (dispatch: any) => {
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          for (let i = 0; i < Responedata.length; i++) {
            //find distance of two coordinates
            if (Responedata[i].trade_zone_id.zone_json) {
              if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(Responedata[i].trade_zone_id.zone_json))) {
                data.push(Responedata[i]);
              }
            }
          }
          //after store is find out check if the store is active and if it not active,
          //then find its backup stores array and push the backup store

          if (data.length > 0 && data[0].is_active == 0) {
            if (data[0].mainStoreId.length > 0) {
              let backupStoreData: any = [];
              data[0].mainStoreId.map((backupStore: any) => {
                if (backupStore.backupStoreId.is_active == 1) {
                  backupStoreData.push(backupStore);
                }
              })
              //sorting array with priority basis if backup stores are greater than 1
              if (backupStoreData.length > 1) {
                backupStoreData.sort((a: any, b: any) => {
                  return a.priority - b.priority
                })
              }
              //finding the backupstore object from response data and match them by store_id
              let foundedStore: any = []
              if (backupStoreData.length > 0) {
                foundedStore.push(Responedata.find((store: any) => backupStoreData[0].backupStoreId.store_id == store.store_id))
                console.log(foundedStore);
                dispatch({
                  type: STORES_LIST,
                  payload: foundedStore,
                  selectStoreId: foundedStore.length > 0 && foundedStore[0].store_id,
                  // deliveryfee: foundedStore.length > 0 && foundedStore[0].country_id.delivery_fee,
                  storeStatus: 0,
                  lat: lat,
                  lng: lng
                });
                sessionStorage.setItem("selectedStore",JSON.stringify(data[0]))
                dispatch(saveTaxDetails(data[0].state_id.state_name))
                if(data[0].isDays == 0) {
                  dispatch(compareStoreTiming(data[0]))
                }
                else {
                  dispatch(compareTimingbyEachDay(data[0]))
                }
              }
            }
            else {
              dispatch({
                type: STORES_LIST,
                payload: [],
                selectStoreId: "",
                // deliveryfee: data.length > 0 && data[0].country_id.delivery_fee,
                storeStatus: 0,
                lat: lat,
                lng: lng
              });
              dispatch(saveTaxDetails(data[0].state_id.state_name))
              if(data[0].isDays == 0) {
                dispatch(compareStoreTiming(data[0]))
              }
              else {
                dispatch(compareTimingbyEachDay(data[0]))
              }
            }
          }
          else {
            dispatch({
              type: STORES_LIST,
              payload: data,
              selectStoreId: data.length > 0 && data[0].store_id,
              deliveryfee: 0,
              storeStatus: 0,
              lat: lat,
              lng: lng
            });
            if (data.length > 0) {
              sessionStorage.setItem("selectedStore",JSON.stringify(data[0]))
              dispatch(saveTaxDetails(data[0].state_id.state_name))
              if(data[0].isDays == 0) {
                dispatch(compareStoreTiming(data[0]))
              }
              else {
                dispatch(compareTimingbyEachDay(data[0]))
              }
            }
          }
          // dispatch(GetLSMitembyStoreId(data[0].store_id))
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: STORES_LIST,
            payload: data,
            deliveryfee: 0,
            storeStatus: 0,
            lat: lat,
            lng: lng
          });
        } else {
          alert("Connection Lost!!");
        }
      });
  };
};

export const findStoreforLSM = (lat: any, lng: any, timeZoneData: any) => {
  console.log("in find store for lsm action")
  return async (dispatch: any) => {
    dispatch({
      type: MENU_GROUPS_LIST,
      payload: [],
      groupsData: [],
      loaderFlag: true
    });
    dispatch({
      type: STORE_OPEN_FLAG,
      openFlag: true
    });
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          for (let i = 0; i < Responedata.length; i++) {
            if (Responedata[i].trade_zone_id.zone_json) {
              if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(Responedata[i].trade_zone_id.zone_json))) {
                data.push(Responedata[i]);
              }
            }
          }
          if (data.length > 0) {
            if (data[0].is_active === 1) {
              dispatch(menuGroupsList(data[0].store_id, timeZoneData))
              dispatch(menuGroupsListHome(data[0].store_id, timeZoneData))
              // dispatch(TopList(data[0].store_id))
              dispatch(TopItemsList(data[0].store_id));
              dispatch(menuHeroItems(data[0].store_id))
              // dispatch(TopDealsHome(data[0].store_id))
              dispatch({
                type: STORES_LIST,
                payload: data,
                selectStoreId: data.length > 0 && data[0].store_id,
                deliveryfee: 0,
                storeStatus: 0,
              });
              dispatch({
                type: MENU_STORE_ID,
                payload: data.length > 0 && data[0].store_id
              })
              sessionStorage.setItem("selectedStore",JSON.stringify(data[0]))
              dispatch(saveTaxDetails(data[0].state_id.state_name))
              if(data[0].isDays == 0) {
                dispatch(compareStoreTiming(data[0]))
              }
              else {
                dispatch(compareTimingbyEachDay(data[0]))
              }
            }
            else {
              console.log("store is inactive")
              dispatch({
                type: LOCATION_MODAL_FLAG,
                payload: true
              })
              dispatch(menuGroupsList(null, timeZoneData))
              dispatch(menuGroupsListHome(null, timeZoneData))
              // dispatch(TopList(null))
              dispatch(TopItemsList(null));
              dispatch(menuHeroItems(null))
              // dispatch(TopDealsHome(null))
              dispatch({
                type: MENU_STORE_ID,
                payaload: null,
              })
              dispatch({
                type: STORE_OPEN_FLAG,
                openFlag: true
              });
              dispatch({
                type: LSM_ITEMS,
                payload: [],
                emptyLsmItems: true,
                changeLocationModal: true,
                groupName: 'LSM',
              });
            }

          }
          else {
            // toast.warn("You are out of coverage from trade zone!")
            // dispatch(GetLSMitembyStoreId(null))
            dispatch({
              type: LOCATION_MODAL_FLAG,
              payload: true
            })
            dispatch(menuGroupsList(null, timeZoneData))
            dispatch(menuGroupsListHome(null, timeZoneData))
            // dispatch(TopList(null))
            dispatch(TopItemsList(null));
            dispatch(menuHeroItems(null))
            // dispatch(TopDealsHome(null))
            dispatch({
              type: MENU_STORE_ID,
              payaload: null,
            })
            dispatch({
              type: STORE_OPEN_FLAG,
              openFlag: true
            });
            dispatch({
              type: LSM_ITEMS,
              payload: [],
              emptyLsmItems: true,
              changeLocationModal: true,
              groupName: 'LSM',
            });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: STORES_LIST,
            payload: data,
            deliveryfee: 0,
            storeStatus: 0,
          });
        } else {
          alert("Connection Lost!!");
        }
      });
  };
};

  //Compare time make array of hours
  export const HoursArray = (hourDiff: any, starttime: any) => {
    if (String(hourDiff).indexOf('-') !== -1) {
      hourDiff = hourDiff + 24;
    }
    var hours: any = [];
    let hour = starttime;
    for (let i = 0; i <= hourDiff; i++) {
      hour = starttime + i;
      let AmOrPm = '';
      hour >= 12 && hour < 24 ? (AmOrPm = 'pm') : (AmOrPm = 'am');
      hour = hour % 12 || 12;
      hours.push(hour + AmOrPm);
    }
    var string = hours.join(':');
    return string;
  };

  export const compareStoreTiming = (store:any) => {
    return async (dispatch: any) => {
      Api.get(`/customer/state/timezone/${store.store_id}`)
      .then((response) => {
        console.log("store open closing time", store)
        let today = response.data.successResponse;
        //convert the current time 24 hour to 12 hour
        let CurrentHours: any = today.split(":")[0];
        let currentMinutes: any = today.split(":")[1];
          let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
          CurrentHours = CurrentHours % 12 || 12;
          let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
          //define hours or minutes
          var timeStartMin = new Date(
            '01/01/2007 ' + store.store_open_time,
          ).getMinutes();
          var timeEndMin = new Date(
            '01/01/2007 ' + store.store_close_time,
          ).getMinutes();
          var timeStartHours = new Date(
            '01/01/2007 ' + store.store_open_time,
          ).getHours();
          var timeEndHours = new Date(
            '01/01/2007 ' + store.store_close_time,
          ).getHours();
          var hourDiff: any = timeEndHours - timeStartHours;
          let HourString = HoursArray(hourDiff, timeStartHours)
          let splitHourArray = HourString.split(":")
          let storeOpen = false;
          if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
            if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                //if Pick Hour with am/pm is exist in HourString at last index
                storeOpen = false;
                // toast.error(i18next.t('errorMsg.store_closed'));
              } else {
                //store is open
                storeOpen = true
              }
            } else {
              if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                  if (currentMinutes < timeEndMin) {
                    storeOpen = true
                    //store is open
                  } else {
                    storeOpen = false;
                    // toast.error(i18next.t('errorMsg.store_closed'));
                  }
                } else {
                  //store is open
                  storeOpen = true;
                }
              } else { //if store opening or closing minute is not 00
                if (
                  currentMinutes > timeStartMin ||
                  currentMinutes < timeEndMin
                ) {
                  //store is open 
                  storeOpen = true;
                } else {
                  storeOpen = false;
                  // toast.error(i18next.t('errorMsg.store_closed'));
                }
              }
            }
          } else {
            storeOpen = false;
            // toast.error(i18next.t('errorMsg.store_closed'));
          }
          dispatch({
            type: STORE_OPEN_FLAG,
            openFlag: storeOpen
          });
      })
      }
}

export const compareTimingbyEachDay = (store: any) => {
  return async (dispatch: any) => {
  Api.get(`/customer/state/timezone/${store.store_id}`)
  .then((response: any) => {
    let dateIndexServer = response.data.successLaterResponse.indexOf("T")
    let serverDate = response.data.successLaterResponse.slice(0, dateIndexServer)
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let weekDaysTimingArr = JSON.parse(store.daysTiming)
    let todayDay = new Date(serverDate).getDay();
    let dayName = days[todayDay];
    let storeOpen = false;
    weekDaysTimingArr.map((storeDay: any) => {
      if (dayName == storeDay.day) {
        let today = new Date();
        //convert the current time 24 hour to 12 hour
        // let CurrentHours: any = today.getHours();
        // let currentMinutes: any = today.getMinutes();
        let CurrentHours: any = response.data.successResponse.split(":")[0]
        let currentMinutes: any = response.data.successResponse.split(":")[1]
        let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
        CurrentHours = CurrentHours % 12 || 12;
        let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
        //define hours or minutes
        var timeStartMin = new Date(
          '01/01/2007 ' + storeDay.opening,
        ).getMinutes();
        var timeEndMin = new Date(
          '01/01/2007 ' + storeDay.closing,
        ).getMinutes();
        var timeStartHours = new Date(
          '01/01/2007 ' + storeDay.opening,
        ).getHours();
        var timeEndHours = new Date(
          '01/01/2007 ' + storeDay.closing,
        ).getHours();
        var hourDiff: any = timeEndHours - timeStartHours;
        let HourString = HoursArray(hourDiff, timeStartHours);
        let splitHourArray = HourString.split(":")

        if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
          if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
            if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
              //if Pick Hour with am/pm is exist in HourString at last index
              storeOpen = false;
            } else {
              storeOpen = true;
            }
          } else {
            if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                if (currentMinutes < timeEndMin) {
                  storeOpen = true
                } else {
                  storeOpen = false;
                }
              } else {
                storeOpen = true;
              }
            } else { //if store opening or closing minute is not 00
              if (
                currentMinutes > timeStartMin ||
                currentMinutes < timeEndMin
              ) {
                storeOpen = true
              } else {
                storeOpen = false;
              }
            }
          }
        } else {
          storeOpen = false;
        }
        dispatch({
          type: STORE_OPEN_FLAG,
          openFlag: storeOpen
        });
        return;
      }
    })
  })
}
}

export const selectMenuForPickup = (store: any, data: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: STORE_OPEN_FLAG,
      openFlag: true
    });
    dispatch({
      type: MENU_STORE_ID,
      payload: store.store_id
    })
    dispatch({
      type: STORES_LIST,
      payload: store,
      selectStoreId: store.store_id,
      deliveryfee: 0,
      storeStatus: 0,
    });
    dispatch(menuGroupsList(store.store_id, data))
    dispatch(menuGroupsListHome(store.store_id, data))
    // dispatch(TopList(store.store_id))
    dispatch(TopItemsList(store.store_id));
    dispatch(menuHeroItems(store.store_id))
    // dispatch(TopDealsHome(data[0].store_id))
    dispatch(saveTaxDetails(store.state_id.state_name))
    if(store.isDays == 0) {
      dispatch(compareStoreTiming(store))
    }
    else {
      dispatch(compareTimingbyEachDay(store))
    }
    console.log("store open flag in pickup modal")
    
  };
};

export const GetLSMitembyStoreId = (storeId: any) => {
  console.log("herre")
  let obj = {
    mode: "online"
  }
  return async (dispatch: any) => {
    Api.post(`menu/getItemsForLocalStore/${storeId}`, obj)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data)
          let Responedata = response.data.menuData;
          dispatch({
            type: LSM_ITEMS,
            payload: Responedata,
            emptyLsmItems: true,
            groupName: 'LSM',
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log("One")
          dispatch({
            type: LSM_ITEMS,
            payload: [],
            emptyLsmItems: true,
            groupName: 'LSM',
          });
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
          alert("Connection Lost!!!");
        }
      });
  };
};

export const GetSpecificitembyStoreId = (storeId: any) => {
  let obj = {
    mode: "online"
  }
  return async (dispatch: any) => {
    Api.post(`menu/getItemsForSpecificStore/${storeId}`, obj)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data)
          let Responedata = response.data.menuData;
          dispatch({
            type: SPECIFIC_STORE_ITEMS,
            payload: Responedata,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: SPECIFIC_STORE_ITEMS,
            payload: [],
          });
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
          alert("Connection Lost!!!");
        }
      });
  };
};

//localStores with Circle Radius
// export const findLocalStores = (lat: any, lng: any) => {
//   return async (dispatch: any) => {
//     Api.get("menu/stores")
//       .then((response) => {
//         if (response.data.success) {
//           let Responedata = response.data.successResponse;
//           //find distance of two coordinates
//           const calcCrow = (
//             locationLat: any,
//             locationLong: any,
//             storeLat: any,
//             storeLong: any
//           ) => {
//             var R = 6371; // km
//             var differenceLat = toRad(storeLat - locationLat);
//             var differenceLon = toRad(storeLong - locationLong);
//             var locationLatRadian = toRad(locationLat);
//             var storeLatRadian = toRad(storeLat);
//             var a =
//               Math.sin(differenceLat / 2) * Math.sin(differenceLat / 2) +
//               Math.sin(differenceLon / 2) *
//               Math.sin(differenceLon / 2) *
//               Math.cos(locationLatRadian) *
//               Math.cos(storeLatRadian);
//             var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//             var distanceInMeter = R * c * 1000;
//             return distanceInMeter;
//           };
//           // Converts numeric degrees to radians
//           const toRad = (Value: any) => {
//             return (Value * Math.PI) / 180;
//           };
//           let data: any = [];
//           let store_list: any = [];
//           for (let i = 0; i < Responedata.length; i++) {
//             store_list.push(
//               calcCrow(lat, lng, Responedata[i].lat, Responedata[i].lng)
//             );
//             if (
//               calcCrow(lat, lng, Responedata[i].lat, Responedata[i].lng) <=
//               Responedata[i].trade_zone_coverage
//             ) {
//               data.push(Responedata[i]);
//             }
//           }
//           // if (data.length == 0) {
//           //   let nearest = Math.min.apply(Math, store_list);
//           //   for (let i = 0; i < Responedata.length; i++) {
//           //     if (
//           //       calcCrow(lat, lng, Responedata[i].lat, Responedata[i].lng) ==
//           //       nearest
//           //     ) {
//           //       data.push(Responedata[i]);
//           //     }
//           //   }
//           //   dispatch({
//           //     type: STORES_LIST,
//           //     payload: data,
//           //     deliveryfee: response.data.successResponse[0].delivery_fee,
//           //     storeStatus: 1,
//           //   });
//           //   // window.location.href = "/";
//           // } else {
//           dispatch({
//             type: STORES_LIST,
//             payload: data,
//             selectStoreId: data.length > 0 && data[0].store_id,
//             deliveryfee: data.length > 0 && data[0].delivery_fee,
//             storeStatus: 0,
//           });
//           // window.location.href = "/";
//           // }
//         }
//       })
//       .catch((err) => {
//         if (err.response) {
//           let error;
//           let data: any = [];
//           if (typeof err.response.data.message === "string") {
//             error = err.response.data.message;
//           } else if (typeof err.response.data.message === "object") {
//             error = err.response.data.message[0].replace(/_/g, " ");
//           }
//           window.location.href = "/";
//           dispatch({
//             type: STORES_LIST,
//             payload: data,
//             deliveryfee: 0,
//             storeStatus: 0,
//           });
//         } else {
//           alert("Connection Lost");
//         }
//       });
//   };
// };
//saveSelectStoreId
export const saveSelectStoreId = (store: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SELECT_STORE_ID,
      selectStoreId: store.store_id,
    });
    dispatch(saveTaxDetails(store.state_id.state_name))
  };
};
export const saveStore = (store: any) => {
  console.log("--------------Store--------------",store)
  return function (dispatch: any) {
    dispatch({
      type: SELECT_STORE,
      storeSelected: store,
    });

  };
};
export const SaveOpenLocModalFlag = (flag: any) => {
  console.log("--------------Flag--------------",flag)
  return function (dispatch: any) {
    dispatch({
      type: LOCATION_MODAL_FLAG,
      storeSelected: flag,
    });

  };
};
export const saveTaxDetails = (state_name: any) => {
  return function (dispatch: any) {
    let obj = {
      state_name: state_name
    }
    Api.post(`menu/tax`, obj)
      .then((response) => {
        if (response.data.success) {
          console.log("tax value", response.data.success)
          let responseData = response.data.successResponse
          responseData.map((taxVariations: any) => {
            if (taxVariations.payment_method == 'cash') {
              dispatch({
                type: GET_TAX_CASH,
                payload: taxVariations,
                deliveryfee: taxVariations.delivery_fee
              });
            }
            else if (taxVariations.payment_method == 'card') {
              dispatch({
                type: GET_TAX_CARD,
                payload: taxVariations,
                deliveryfee: taxVariations.delivery_fee,
              });

            }
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          // console.log(error);
        } else {
          // console.log("ok tax");
        }
      });
  }
}

export const saveDeliveryFee = (delivery_fee: any) => {
  return function (dispatch: any) {
    dispatch({
      type: DELIVERY_FEE,
      deliveryfee: delivery_fee,
    });
  };
};
//get comboslist from selectstores
export const menuCombosList = () => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    let data: any = {
      mode: 'online'
    }
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    data.order_channel = 'web';
    Api.post("menu/combos", data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MENU_COMBO_LIST,
            payload: response.data.successResponse,
          });
          // console.log("success combo");
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: MENU_COMBO_LIST,
            payload: [],
          });
        } else {
          // console.log("ok Combo");
        }
      });
  };
};
// social login for k2g
export const loginCustomerWithSocial = (data: any, history: any) => {
  console.log('googleLogin', data)
  return async (dispatch: any) => {
    Api.post("/customer/signinwithsocial", data)
      .then(async (response) => {
        if (response.data.success) {

          let token = response.data.accessToken;
          await localStorage.setItem("token", token);
          await localStorage.setItem("name", response.data.customer.login_name);
          await localStorage.setItem("email", response.data.customer.email_address);
          await localStorage.setItem("gender", response.data.customer.gender);
          await localStorage.setItem("phone", response.data.customer.phone_number);
          await localStorage.setItem("id", response.data.customer.customer_id);
          await localStorage.setItem("profile_pic_social", response.data.customer.profile_pic);
          await localStorage.setItem("customer_birthday", response.data.customer.customer_birthday);
          await localStorage.setItem("profiledata", response.data.customer);
          let phone = response.data.customer.phone_number;
          if (phone && phone !== '' && phone !== null) {
            var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
            var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/;
            if (phone.match(phoneno) || phone.match(mobileno)) {
              history.push('/')
            }
            else {
              history.push({pathname: '/profile', search: window.location.search})
              dispatch({
                type: URL_MATCH_METHOD,
                url: 'updateprofile',
              });
            }
          }
          else {
            history.push({pathname:'/profile', search: window.location.search})
            dispatch({
              type: URL_MATCH_METHOD,
              url: 'updateprofile',
            });
          }
          var str = response.data.profile_pic;
          var pos = str.search("https:");
          // console.log(pos)
          if (pos == "-1") {
            // console.log('not found')
            await localStorage.setItem(
              "profile_pic",
              response.data.profile_pic
            );
          } else {
            // console.log('found')
            await localStorage.setItem("fileUri", response.data.profile_pic);
          }
          // window.location.href = "/"
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: LOG_IN,
            isLoggedInError: true,
            loginErrMessage: err.response.data.message
          });
        }
      });
  };
};
///top deals list

export const TopList = (store_id: any) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    let data: any = {
      mode: 'online'
    }
    if (store_id) {
      data.store_id = store_id
    }
    else {
      data.store_id = null;
    }
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    data.order_channel = 'web';
    Api.post("menu/topDeals", data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: TOP_LIST,
            payload: response.data.successResponse,
          });
          // console.log("success combo");
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: MENU_COMBO_LIST,
            payload: [],
          });
        } else {
          // console.log("ok Combo");
        }
      });
  };
};

//TOP DEALS HOME SCREEN
export const TopDealsHome = (store_id: any) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    let data: any = {
      mode: 'online'
    }
    console.log(store_id, "top 4 deal")
    if (store_id) {
      data.store_id = store_id
    }
    else {
      data.store_id = null;
    }
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    data.order_channel = 'web';
    Api.post("menu/top4Deals", data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: TOP_DEALS_HOME,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: MENU_COMBO_LIST,
            payload: [],
          });
        } else {
          // console.log("ok Combo");
        }
      });
  };
};


export const TopItemsList = (store_id: any) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    let data: any = {
      mode: 'online'
    }
    if (store_id) {
      data.store_id = store_id;
    }
    else {
      data.store_id = null;
    }
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    data.order_channel = 'web';
    Api.post("menu/topItems", data)
      .then((response) => {
        console.log("response items", response);
        if (response.data.success) {
          dispatch({
            type: TOP_SELLING_ITEMS,
            payload: response.data.successResponse,
          });
          // console.log("success combo");
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: MENU_COMBO_LIST,
            payload: [],
          });
        } else {
          // console.log("ok Combo");
        }
      });
  };
};

//groupslist show in header menu

export const menuGroupsList = (store_id: any, timeZoneData?: any) => {
  return async (dispatch: any) => {
    // dispatch({
    //   type: MENU_GROUPS_LIST,
    //   payload: [],
    //   groupsData: [],
    //   loaderFlag: true
    // });
    let token: any = await localStorage.getItem('token');
    let data: any = {
      mode: 'online',
      timeZoneData: timeZoneData
    };
    if (store_id) {
      data.store_id = store_id
    } else {
      data.store_id = null
    }

    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    Api.post(`menu/allGroups`, data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MENU_GROUPS_LIST,
            payload: response.data.groups,
            groupsData: response.data.groupsData,
            loaderFlag: false
          });
          // dispatch({
          //   type: MENU_ITEMS,
          //   payload: [],
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // dispatch({
          //   type: MENU_ITEMS,
          //   payload: [],
          // });
          dispatch({
            type: MENU_GROUPS_LIST,
            payload: [],
            groupsData: [],
            loaderFlag: false
          });
        } else {
          // console.log("ok grouplist");
        }
      });
  };
};

//menu item for home
export const menuGroupsListHome = (store_id: any, timeZoneData: any) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    let data: any = {
      mode: 'online',
      timeZoneData: timeZoneData
    };
    if (store_id) {
      data.store_id = store_id
    }
    else {
      data.store_id = null
    }

    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    Api.post(`menu/groupsForHome`, data)
      .then((response) => {
        if (response.data.success) {
          console.log("groups home", response.data)
          dispatch({
            type: MENU_GROUPS_LIST_HOME,
            payload: response.data.successResponse,
          });
          // dispatch({
          //   type: MENU_ITEMS,
          //   payload: [],
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // dispatch({
          //   type: MENU_ITEMS,
          //   payload: [],
          // });
          dispatch({
            type: MENU_GROUPS_LIST_HOME,
            payload: [],
          });
        } else {
          // console.log("ok grouplist");
        }
      });
  };
};

//groupslist show menu k
export const menuItemsListByGroupsID = (data: any) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem("token");
    let user: any = {
      mode: "online"
    };
    if (token) {
      var decoded: any = jwt_decode(token);
      user.customer_id = decoded.customer.customer_id;
    }

    user.order_channel = "web"
    Api.post(`menu/items/${data.group_id}`, user)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MENU_ITEMS,
            payload: response.data.successResponse,
            groupName: data.group_name,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // console.log(error);
          dispatch({
            type: MENU_ITEMS,
            payload: [],
          });
        } else {
          // console.log("ok grouplist item");
        }
      });
  };
};

//Order list of customer

export const ordersList = () => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem("token");
    if (token) {
      let decoded: any = jwt_decode(token);
      Api.get(`/customer/orders/${decoded.customer.customer_id}`, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: ORDER_LIST,
              payload: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: ORDER_LIST,
              payload: [],
            });
          } else {
            // console.log("ok  Orderlist");
          }
        });
    }
  };
};

//Get Cart

export const getCart = (history?: any) => {
  return async (dispatch: any) => {
    let cart: any = await sessionStorage.getItem("cart");
    if (cart === null || cart.length <= 0) {
      cart = [];
    }
    dispatch({
      type: GET_CART,
      payload: cart,
    });
  };
};

// save cart

export const saveCart = (cart: any) => {
  //type of cart is array
  return async (dispatch: any) => {
    //convert cart to string
    let strCart = JSON.stringify(cart);
    //save cart
    sessionStorage.setItem("cart", strCart);
    getCart();
    dispatch({
      type: SAVE_CART,
      success: true,
    });
  };
};

// k2g franchise

export const franchinseApplication = (data: any) => {
  console.log('franchise', data)
  return function (dispatch: any) {
    try {
      Api.post(`customer/franchise_application`, data)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            console.log("Success", responseData);
            dispatch({
              type: FRANCHISE_APPLICATION,
              isFilled: true,
            });
            toast.info("Your application submitted successfully", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            window.location.href = '/'
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              if (err.response.data.code === "ER_DUP_ENTRY") {
                dispatch({
                  type: FRANCHISE_APPLICATION,
                  isFilled: false,
                });
                // alert("Email Already Exist");
                // error = "Email Already Exist";
              } else {
                error = err.response.data.message;
              }
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            console.log(err.response);
          } else {
            // console.log("NoSignUp");
          }
        });
    } catch (e) {
      // console.log("NoSignUp", e);
    }
  };
};


// OPTP trackOrder

export const trackOrder = (data: any) => {
  return function (dispatch: any) {
    try {
      Api.post(`customer/trackOrder`, data)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            dispatch({
              type: ORDER_DATA,
              storeData: responseData
            })
            // toast.error("Email send successfully", {
            //   position: toast.POSITION.TOP_CENTER,
            //   hideProgressBar: true,
            //   autoClose: 3000,
            // });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            dispatch({
              type: ORDER_DATA,
              storeData: {}
            })
            toast.warning(i18next.t('errorMsg.no_track'), {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else {
            // console.log("NoSignUp");
          }
        });
    } catch (e) {
      // console.log("NoSignUp", e);
    }
  };
};
//k2g restuarantLocator
export const getStores = () => {
  return function (dispatch: any) {
    try {
      Api.get(`customer/stores`)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            dispatch({
              type: RESTUARANTS,
              stores: responseData
            })
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            toast.warning(error, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else {
            // console.log("NoSignUp");
          }
        });
    } catch (e) {
      // console.log("NoSignUp", e);
    }
  };
};
export const souqMediaStores = () => {
  return function (dispatch: any) {
    try {
      Api.get(`customer/souqmedia_stores`)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            dispatch({
              type: SOUQ_MEDIA_RESTUARANTS,
              stores: responseData
            })
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            toast.warning(error, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else {
            // console.log("NoSignUp");
          }
        });
    } catch (e) {
      // console.log("NoSignUp", e);
    }
  };
};
export const searchStore = (data: any, store_id: any) => {
  return function (dispatch: any) {
    if (store_id !== 'All') {
      let storeData: any = data && data.filter((store: any) => store_id == store.id);
      dispatch({
        type: SEARCH_RESTUARANTS,
        searchStore: storeData
      })
    } else {
      dispatch({
        type: SEARCH_RESTUARANTS,
        searchStore: data
      })
    }

  };
};

//save delivery fee of store
export const saveStoreName = (storeID: any) => {
  return function (dispatch: any) {
    dispatch({
      type: STORE_NAME,
      StoreID: storeID,
    });
  };
};

// get store id
export const saveStoreID = (store_id: any) => {
  return function (dispatch: any) {
    dispatch({
      type: STORE_ID,
      Store_ID: store_id,
    });
  };
};



/// type save pickup, delivery save
export const saveType = (type: any) => {
  return function (dispatch: any) {
    dispatch({
      type: STORE_TYPE,
      Types: type,
    });
  };
};

/// type save pickup, delivery save
export const saveOrderType = (type: any) => {
  return function (dispatch: any) {
    dispatch({
      type: ORDER_TYPE,
      orderType: type,
    });
  };
};

// type save pickup, payment method
export const savePaymentMethod = (payment_method: any) => {
  return function (dispatch: any) {
    dispatch({
      type: PAYMENT_METHOD,
      paymentMethod: payment_method,
    });
  };
};
export const saveurlMethod = (lookupurl: any) => {
  return function (dispatch: any) {
    dispatch({
      type: URL_MATCH_METHOD,
      url: lookupurl,
    });
  };
};

// type save pickup, payment method
export const saveOrderid = (order_id: any) => {
  return function (dispatch: any) {
    dispatch({
      type: ORDER,
      order_id: order_id,
    });
  };
};
/// type save home, office, other
export const saveAddressTypeUser = (type: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_ADDRESS_USER,
      saveAddressUser: type,
    });
  };
};
export const saveOrderPeriod = (period: any) => {
  return function (dispatch: any) {
    dispatch({
      type: ORDER_PERIOD,
      orderPeriod: period,
    });
  };
};
export const saveHouseDetail = (house: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_HOUSEDETAIL,
      houseDetail: house,
    });
  };
};
// export const saveStreetDetail = (street: any) => {
//   return function (dispatch: any) {
//     dispatch({
//       type: SAVE_STREETDETAIL,
//       streetDetail: street,
//     });
//   };
// };
export const saveAddressType = (addressType: any) => {
  console.log("addressType", addressType);
  return function (dispatch: any) {
    dispatch({
      type: SAVE_ADDRESSTYPE,
      addressType: addressType,
    });
  };
};
//get tax value by store state
export const getTaxValue = (data_tax: any) => {
  return async (dispatch: any) => {
    Api.get(`menu/tax_value/${data_tax}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_TAX,
            payload: response.data.successResponse.tax_percent,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          // console.log(error);
        } else {
          // console.log("ok tax");
        }
      });
  };
};

// saveorder or get orderid from API
export const saveOrder = (data: any) => {
  return async (dispatch: any) => {
    // let token: any = await localStorage.getItem('token');
    // if (token) {
    //   var decoded: any = jwtDecode(token);
    //   data.customer_id = decoded.customer.customer_id;
    Api.post(
      `/customer/save_order`,
      data
      // {
      //   headers: {Authorization: 'Bearer ' + token},
      // }
    )
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ORDER_ID,
            payload: response.data.order_id,
            // order_id: response.data.order_id,
            // amount: data.order_grossprice,
          });
          // console.log('acton',response.data.order_id)
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // console.log('order id not')
        } else {
          // console.log('order id get')
        }
      });
    // }
  };
};
export const saveOrderk2g = (
  data: any,
  store: any,
  tax: any
) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem("token");
    // let guest = true;
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    Api.post(`/customer/save_order`, data)
      .then(async (response) => {
        if (response.data.success) {
          console.log(response.data)

          //for invoice email
          if (tax) {
            response.data.order.tax = tax;
          }
          if (store) {
            response.data.order.store = store.store_name;
            response.data.order.store_address = store.address;
          }
          if (data.guest && data.guest === true) {
            response.data.order.name = await localStorage.getItem("guestname");
            response.data.order.email = await localStorage.getItem("guestemail");
            response.data.order.phone = await localStorage.getItem("guestphone");
            dispatch(saveOrderid(response.data.order.order_id))
          } else {
            response.data.order.name = await localStorage.getItem("name");
            response.data.order.email = await localStorage.getItem("email");
            response.data.order.phone = await localStorage.getItem("phone");
            dispatch({
              type: PAYMENT,
              PaymentSuccess: "success",
            });
            dispatch(saveOrderid(response.data.order.order_id))
          }
          sessionStorage.removeItem("cart");
          dispatch({
            type: PAYMENT,
            PaymentSuccess: "success",
            successFlag: true,
          });
        }
        else if (response.data.successResponse.redirect_url) {
          window.location.href = response.data.successResponse.redirect_url;
        }
        else if (response.data.statusCode == 400) {
          toast.error(response.data.successResponse);
        }
        else if (response.data.order == "redirect url") {
          window.location.href = "/thank-you"
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          console.log("error", error);
        } else {
        }
      });
  };
};
export const cardTransaction = (
  data: any
) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem("token");
    // let guest = true;
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    Api.post(`/customer/paytabs_payment/${data.customer_id}`, data)
      .then(async (response) => {
        if (response.data.success) {
          console.log(response.data)
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          console.log("error", error);
        } else {

        }
      });
  };
};

export const paymentProcess = (data: any, cardInfo?:any) => {
  return async (dispatch: any) => {
    let GuestName = await localStorage.getItem("guestname");
    if(cardInfo) {
      data.transaction_id = cardInfo.transaction_id;
      data.payment_status = cardInfo.payment_status;
      data.card_last_digits = cardInfo.card_last_digits;
      data.card_brand = cardInfo.card_brand;
    }
    Api.post(`/customer/save_payment`, data)
      .then(async (response) => {
        if (response.data.success) {
          

          console.log("payment", response.data.success);
          // window.location.href = "/";
          // Toast.show({
          //   text: 'Thank You! Your order is successfull submitted',
          //   buttonText: 'OK',
          //   duration: 5000,
          //   type: 'success',
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
        }
      });
  };
};
// save order later k2g

export const saveLaterOrder = (data: any, store: any, tax: any) => {
  console.log("Final object for order placing", data)
  return async (dispatch: any) => {
    console.log('saveorder', data, store, tax)
    let token: any = await localStorage.getItem('token');
    // let guest = true;

    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    Api.post(`/customer/save_order_later`, data)
      .then(async (response) => {
        if (response.data.success) {
          // dispatch({
          //   type: COUPON_DATA,
          //   payload: '',
          // });
          //for invoice email
          response.data.order.tax = tax;
          if (store) {
            response.data.order.store = store.store_name;
            response.data.order.store_address = store.address;
          }
          if (data.guest && data.guest === true) {
            response.data.order.name = await localStorage.getItem("guestname");
            response.data.order.email = await localStorage.getItem("guestemail");
            response.data.order.phone = await localStorage.getItem("guestphone");
          } else {
            response.data.order.name = await localStorage.getItem("name");
            response.data.order.email = await localStorage.getItem("email");
            response.data.order.phone = await localStorage.getItem("phone");
            dispatch({
              type: PAYMENT,
              PaymentSuccess: "success",
            });
          }
          //
          let obj: any = {
            payment_amount: data.order_grossprice,
            payment_method: data.payment_method,
            payment_status: "pending",
            order_id: response.data.order.order_id,
            orderInfo: response.data.order,
            datetime: data.laterdatetime
          };
          dispatch(laterPaymentProcess(obj));
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          // Toast.show({
          //   text: error,
          //   buttonText: 'OK',
          //   duration: 5000,
          //   type: 'danger',
          // });
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};

export const laterPaymentProcess = (data: any) => {
  console.log('paymentLater', data)
  return async (dispatch: any) => {
    Api.post(`/customer/save_payment_later`, data)
      .then((response) => {
        if (response.data.success) {
          sessionStorage.removeItem("cart");
          dispatch({
            type: PAYMENT,
            PaymentSuccess: "success",
            successFlag: false,
            futuresuccessFlag: true,
          });
          // window.location.href = "/";
          // Toast.show({
          //   text: 'Your order is successfull according your time',
          //   buttonText: 'OK',
          //   duration: 5000,
          //   type: 'success',
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          // Toast.show({
          //   text: error,
          //   buttonText: 'OK',
          //   duration: 5000,
          //   type: 'danger',
          // });
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};

// get combo id
export const getComboDetailID = (id: any) => {
  return function (dispatch: any) {
    dispatch({
      type: COMBO_DETAIL_ID,
      combo_ID: id,
    });
    // history.push('/detail');
    // this.props.history.push('/detail');
  };
};

export const saveGuestAddress = (address_save_session: any) => {
  console.log('saveaddressAction', address_save_session)
  return async function (dispatch: any) {
    dispatch({
      type: ADDRESS_GUEST,
      Address: address_save_session,
    });
    await sessionStorage.setItem("address_save_session", address_save_session);
  };
};

export const saveAddressExtraDetails = (extra_details: any) => {
  console.log('extra_detailsAddress', extra_details)
  return async function (dispatch: any) {
    dispatch({
      type: EXTRA_ADDRESS_DETAILS,
      payload: extra_details,
    });
    await sessionStorage.setItem("extra_address_details", extra_details);
  };
};

//get combo detail k2g
export const getComboDetailList = (id: any) => {
  return async (dispatch: any) => {
    let obj: any = {
      order_channel: 'web',
      mode: 'online'
    }
    Api.post(`menu/combo/${id}`, obj)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: COMBO_DETAIL,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: COMBO_DETAIL,
            payload: [],
          });
        } else {
          // ok
        }
      });
  };
};

/// get group item detail

export const getItemDetail = (item_id: any) => {
  return async (dispatch: any) => {
    let obj: any = {
      order_channel: 'web',
      mode: 'online'
    }
    Api.post(`menu/item/${item_id}`, obj)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ITEM_DETAIL,
            payload: response.data.successResponse,
          });
        }
        console.log("iditem", response.data.successResponse);
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: ITEM_DETAIL,
            payload: [],
          });
        } else {
        }
      });
  };
};

//add favourite k2g

export const favouriteAdd = (param1: any, store_id?: any, data?: any) => {
  console.log("favourite add")
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem("token");
    if (token) {
      var decoded: any = jwt_decode(token);
      param1.customer_id = decoded.customer.customer_id;
    }
    Api.post("customer/add_wishlist", param1, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {

          if (param1.combo_id && !param1.group_id) {
            toast.error(i18next.t('common.add_favourites'), { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
            if (!param1.topDeal) {
              dispatch(menuCombosList());
            } else {
              dispatch(TopList(store_id));
            }
          } else if (param1.group_id) {
            toast.error(i18next.t('common.add_favourites'), { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
            // dispatch(menuItemsListByGroupsID(param1));
            dispatch(menuGroupsList(store_id, data));
            console.log("in group api call")
          } else if (param1.topItem) {
            toast.error(i18next.t('common.add_favourites'), { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
            dispatch(TopItemsList(store_id));
          }
        }


      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          console.log(error);
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};



export const menuHeroItems = (store_id: any) => {
  return async (dispatch: any) => {
    let data: any = {
      mode: 'online'
    }
    if (store_id) {
      data.store_id = store_id;
    }
    else {
      data.store_id = null;
    }
    Api.post('menu/hero_items', data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: HERO_ITEMS,
            payload: response.data.successResponse
          });
          console.log('hero', response.data.successResponse)
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          dispatch({
            type: HERO_ITEMS,
            payload: [],
          });
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};


export const forgotPassword = (email: any) => {
  return function (dispatch: any) {
    Api.post('/customer/forgot-password', {
      email: email
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: FORGOT,
            isReset: true,
          })
          //  toast.error('We have sent you email on registered account',{hideProgressBar: true, autoClose: 6000})

          //  window.location.href = "/";
        }
      }).catch(err => {
        if (err.response) {
          dispatch({
            type: FORGOT,
            isReset: false,
          })
          let error;
          if (typeof (err.response.data.message) === "string") {
            error = err.response.data.message;
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
  }
}
export const phoneValidationByOTP = (phone: any) => {
  return function (dispatch: any) {
    Api.post('/customer/sendOTPForPhonValidation', {
      phone: phone
    })
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("phoneNumber", phone)
          toast.warning(response.data.successResponse, { position: "top-center", hideProgressBar: true, autoClose: 5000 })
          dispatch({
            type: ACCOUNT_AUTH,
            // respMessage: response.data.successResponse,
            switchTab: '2'
          })
        }
      }).catch(err => {
        if (err.response) {
          toast.warning(err.response.data.message, { position: "top-center", hideProgressBar: true, autoClose: 5000 })
          let error;
          if (typeof (err.response.data.message) === "string") {
            error = err.response.data.message;
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
  }
}
export const ValidateOtpForValidation = (data: any, history: any) => {
  return function (dispatch: any) {
    Api.post('/customer/validateOtpForPhoneValidation', data)
      .then(async (response) => {
        if (response.data.success) {
          toast.error(response.data.successResponse, { position: "top-center", hideProgressBar: true, autoClose: 5000 })
          if (response.data.accessToken) {
            dispatch({
              type: ACCOUNT_AUTH,
              switchTab: '1'
            })
            
            let token = response.data.accessToken;
            await localStorage.setItem("token", token);
            //IF TRUE THEN REDIRECT TO PROFILE SCREEN
            if(data){
              history.push({pathname: "/profile", search: window.location.search} );
            }else{
              history.push({pathname: "/location", search: window.location.search} );
            }
          } else {
            dispatch({
              type: ACCOUNT_AUTH,
              switchTab: '3'
            })
          }
        }
        else if (response.data.success == false) {
          toast.warning(response.data.successResponse, { position: "top-center", hideProgressBar: true, autoClose: 5000 })
        }
      }).catch(err => {
        if (err.response) {
          let error;
          if (typeof (err.response.data.message) === "string") {
            error = err.response.data.message;
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.warning(error, { position: "top-center", hideProgressBar: true, autoClose: 5000 })
        }
      });
  }
}
export const generateOtp = (phone: any) => {
  return function (dispatch: any) {
    Api.post('/customer/generateOtp', {
      phone: phone
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: FORGOT,
            isReset: true,
            respMessage: response.data.successResponse,
            switchTab: '2'
          })
          //  toast.error('We have sent you email on registered account',{hideProgressBar: true, autoClose: 6000})

          //  window.location.href = "/";
        }
      }).catch(err => {
        if (err.response) {
          toast.warning(err.response.data.message, { position: "top-center", hideProgressBar: true, autoClose: 5000 })
          // dispatch({
          //   type: FORGOT,
          //   isReset: false,
          //   respMessage: err.response.data.message,
          //   switchTab: '1'
          // })
          let error;
          if (typeof (err.response.data.message) === "string") {
            error = err.response.data.message;
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
  }
}

export const ValidateOtp = (data: any) => {
  return function (dispatch: any) {
    Api.post('/customer/validateOtp', data)
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: SWITCH_TAB, switchTab: '3' })
          dispatch({
            type: VALIDATE_OTP,
            isValidOtp: true,
            OtprespMessage: response.data.successResponse,
            switchTab: '3'
          })

          //  window.location.href = "/";
        }
        else if (response.data.success == false) {
          dispatch({
            type: VALIDATE_OTP,
            isValidOtp: false,
            OtprespMessage: response.data.successResponse,
            switchTab: '2'
          })
        }
      }).catch(err => {
        if (err.response) {
          console.log(err)
          toast.warning(err.response.data.message, { position: "top-center", hideProgressBar: true, autoClose: 5000 })
          dispatch({
            type: VALIDATE_OTP,
            isValidOtp: false,
            OtprespMessage: err.response.data.message,
            switchTab: '2'
          })
          let error;
          if (typeof (err.response.data.message) === "string") {
            error = err.response.data.message;
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
  }
}

export const changeAuthScreen = () => {
  return async (dispatch: any) => {
    dispatch({
      type: ACCOUNT_AUTH,
      switchTab: '1'
    })
  }
}
 
export const changePasswordOtp = (data: any) => {
  return function (dispatch: any) {
    Api.post('/customer/changePasswordOtp', data)
      .then((response) => {
        if (response.data.success) {
          console.log("password reset")

          // dispatch({type: SWITCH_TAB, switchTab:'3'})
          // console.log(response.data);
          setTimeout(() => {
            window.location.href = "/login";
            dispatch({
              type: VALIDATE_OTP,
              isValidOtp: false,
              OtprespMessage: '',
              switchTab: '3'
            })
          }, 3000);
          toast.error('Your password has been reset. Kindly login with new password.', { hideProgressBar: true, autoClose: 6000 })

        }
        else if (response.data.success == false) {
          // dispatch({
          //   type: VALIDATE_OTP,
          //   isValidOtp: false,
          //   OtprespMessage: response.data.successResponse,
          //   switchTab: '2'
          // })
        }
      }).catch(err => {
        if (err.response) {
          console.log(err)
          toast.warning(err.response.data.message, { position: "top-center", hideProgressBar: true, autoClose: 5000 })
          let error;
          if (typeof (err.response.data.message) === "string") {
            error = err.response.data.message;
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
  }
}



export const favoritesList = () => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    if (token) {
      var decoded: any = jwt_decode(token);
      Api.get(`/customer/wishlist/${decoded.customer.customer_id}`, {
        headers: { Authorization: 'Bearer ' + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: FAV_MENU_ITEM,
              payload: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === 'string') {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === 'object') {
              error = err.response.data.message[0].replace(/_/g, ' ');
            }
            dispatch({
              type: FAV_MENU_ITEM,
              payload: [],
            });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};

export const deletefavourites = (wish_id: number) => {
  console.log('delFav', wish_id)
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    Api.delete(`/customer/del_wish/${wish_id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(favoritesList())
          // Actions.refresh({ key: Math.random() });
          // Toast.show({
          //   text: 'Removed From Favourite items',
          //   type: 'success',
          //   duration: 5000,
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};


export const offersList = () => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    if (token) {
      Api.get(`customer/promos`, {
        headers: { Authorization: 'Bearer ' + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: OFFERS_LIST,
              payload: response.data.successResponse,
              loading: false
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === 'string') {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === 'object') {
              error = err.response.data.message[0].replace(/_/g, ' ');
            }
            dispatch({
              type: OFFERS_LIST,
              payload: [],
              loading: false
            });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};



export const applyCoupon = (data: any) => {
  //type of cart is array
  return async (dispatch: any) => {
    Api.post(`/menu/apply_coupon`, data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: COUPON_DATA,
            payload: response.data.coupon,
            successREsponse: response.data.successResponse
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          toast.error(error);
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};


export const setTabName = (tabName: any) => {
  console.log(tabName, "asdifno")
  return async (dispatch: any) => {
    dispatch({
      type: CHECKOUT_TAB_NAME,
      tabName: tabName
    })
  }
}

export const resetCoupon = (obj: any) => {
  console.log(obj, "asdifno")
  return async (dispatch: any) => {
    dispatch({
      type: COUPON_DATA,
      payload: {},
      successREsponse: false
    });
  }
}

export const getStoreById = (store_id: any) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    if (token) {
      Api.get(`customer/store/${store_id}`, {
        headers: { Authorization: 'Bearer ' + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: SELECT_STORE,
              selectedStore: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === 'string') {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === 'object') {
              error = err.response.data.message[0].replace(/_/g, ' ');
            }
            // Toast.show({
            //   text: error,
            //   buttonText: 'OK',
            //   duration: 5000,
            //   type: 'danger',
            // });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};
export const getCardDetails = (obj: any) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    if (token) {
      Api.post(`customer/fetch_card_details`,obj, {
        headers: { Authorization: 'Bearer ' + token },
      })
        .then((response) => {
          if (response.data.success) {
            console.log(response)
            dispatch({
              type: SAVE_CARD_DETAILS,
              payload: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: SAVE_CARD_DETAILS,
            payload: {}
          })
          if (err.response) {
            let error;
            if (typeof err.response.data.message === 'string') {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === 'object') {
              error = err.response.data.message[0].replace(/_/g, ' ');
            }
          } 
        });
    }
  };
};

export const orderDetail = (order_id: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ORDER_DETAIL,
      payload: [],
      loading: true
    });
    let token: any = await localStorage.getItem('token');
    if (token) {
      Api.get(`/customer/order/${order_id}`, {
        headers: { Authorization: 'Bearer ' + token },
      })
        .then((response) => {
          if (response.data.success) {
            // dispatch(getStoreById(response.data.successResponse.order.store_id))
            setTimeout(() => {
              dispatch({
                type: ORDER_DETAIL,
                payload: response.data.successResponse,
                loading: false
              });
            }, 500);
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: ORDER_DETAIL,
              payload: [],
            });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};

//add user address 
export const addAddressUser = (data: any) => {
  return function (dispatch: any) {
    let token: any = localStorage.token;
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
      // dispatch({
      //   type:EXTRA_ADDRESS_DETAILS,
      //   payload:data.extra_details
      // })
      Api.post(`/customer/add_address`, data, {
        headers: { Authorization: 'Bearer ' + token },
      })
        .then((response) => {
          if (response.data.success) {
            toast.error(response.data.successResponse, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
            // window.location.reload();
          }
        }).catch(err => {
          if (err.response) {
            let error;
            if (typeof (err.response.data) === "string") {
              error = err.response.data;
            } else if (typeof (err.response.data) === "object") {
              error = err.response.data;
            }
          }
        });
    }
  }
}
export const deleteAddress = (addressData: any, address_id: number) => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    Api.delete(`/customer/del_address/${address_id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
      .then((response) => {
        if (response.data.success) {
          var filtered = addressData.filter((element: any, index: any) => {
            return element.address_id !== address_id;
          })
          dispatch({
            type: ADDRESS_LIST,
            payload: filtered
          });
          toast.error("Address deleted succesfully", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};
export const editAddress = (address_id: any, data: any) => {
  return async (dispatch: any) => {

    let token: any = await localStorage.getItem('token');
    if (token) {
      Api.put(`/customer/edit_address/${address_id}`, data, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then((response) => {
          if (response.data.success) {
            toast.error(response.data.successResponse, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === 'string') {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === 'object') {
              error = err.response.data.message[0].replace(/_/g, ' ');
            }

          } else {

          }
        });
    }
  };
};

export const addEmail = (data: any) => {
  return function (dispatch: any) {
    let token: any = localStorage.token;
    Api.post(`/customer/add_email`, data, {
      headers: { Authorization: 'Bearer ' + token },
    })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.successResponse, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
          // window.location.reload();
        }
      }).catch(err => {
        if (err.response) {
          let error;
          if (typeof (err.response.data) === "string") {
            error = err.response.data;
          } else if (typeof (err.response.data) === "object") {
            error = err.response.data;
          }
        }
      });
  }
}

export const addContact = (data: any) => {
  return function (dispatch: any) {
    dispatch({
      type: NETWORK_STATUS,
      payload: true
    });
    let token: any = localStorage.token;
    Api.post(`/customer/add_contact`, data, {
      headers: { Authorization: 'Bearer ' + token },
    })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.successResponse, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
          window.location.href = '/'
        }
      }).catch(err => {
        if (err.response) {
          dispatch({
            type: NETWORK_STATUS,
            payload: false
          });
          let error;
          if (typeof (err.response.data) === "string") {
            error = err.response.data;
          } else if (typeof (err.response.data) === "object") {
            error = err.response.data;
          }
          console.log(error)
        }
      });
  }
}

//Submit complaint 
export const submitComplaint = (data: any) => {
  return function (dispatch: any) {
    let token: any = localStorage.token;
    Api.post(`/customer/feedback_application`, data)
      .then((response) => {
        if (response.data.success) {

          toast.error(<div><h6 className="text-center text-white">THANKS FOR YOUR FEEDBACK!</h6>
            <p className="text-center">
              It's gone to our team and if you've requested us to get in touch we’ll give you a
              call or email within the next 48 hours.   - Your buddies at Tortilla</p></div>, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
          setTimeout(() => {
            window.location.href = '/';
          }, 2000)
        }
      }).catch(err => {
        if (err.response) {
          let error;
          if (typeof (err.response.data) === "string") {
            error = err.response.data;
          } else if (typeof (err.response.data) === "object") {
            error = err.response.data;
          }
          toast.warning(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
          console.log(error)
        }
      });
  }
}
//Submit complaint 
export const submitMailingList = (data: any) => {
  return function (dispatch: any) {
    let token: any = localStorage.token;
    Api.post(`/customer/add_email`, data)
      .then((response) => {
        if (response.data.success) {
          toast.error(<div><h6 className="text-center text-white">THANK YOU FOR JOINING OUR MAILING LIST!</h6>
            <p className="text-center">
              You're all set to start receiving our emails. It's not often we send them out so
              when we do, we reckon it's something worth knowing. Sometimes it's just an
              FYI & other times we want to shout you something for just being awesome!
              If you'd like a break though, click the unsubscribe button on the email. -
              Your buddies at Tortilla </p></div>, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
          setTimeout(() => {
            window.location.href = '/';
          }, 2000)
        }
      }).catch(err => {
        if (err.response) {
          let error;
          error = err.response.data.message;
          toast.warning(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
          console.log(error)
        }
      });
  }
}

export const updateLSM = (status: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_LSM,
      statusInfo: status
    })
  }
}

export const loginUser = (data: any, history: any) => {
  return function (dispatch: any) {
    Api.post('/admin/storeauth', data)
      .then((response) => {
        if (response.data.success) {
          let token = response.data.accessToken;
          localStorage.setItem('mainToken', token)
          history.push("/landing")
        }
      }).catch(err => {
        if (err.response) {
          let error;
          if (typeof (err.response.data.message) === "string") {
            error = err.response.data.message;
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0];
          }
          toast.warn(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
        } else {
          alert(err.message)
        }
      });
  }
}

export const getCustomerByPhone = (id: any) => {
  return function (dispatch: any) {
    Api.get(`/customer/oldCustomerNewPassword/${id}`)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: NEW_CUSTOMER_PHONE_URL,
            code: response.data.code,
            response: response.data.message,
            success: response.data.success,
            name: response.data.name
          })
        }
      }).catch(err => { });
  }
}

export const changePasswordOldCustomer = (newPass: any, phone: any) => {
  return function (dispatch: any) {
    Api.put(`/customer/changePasswordOldCustomer`, {
      phone: phone,
      login_password: newPass
    })
      .then(async (response) => {
        if (response.data.success) {
          toast.error("Password changed successfully, Logging in to your system", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
          let token = response.data.accessToken;
          dispatch({
            type: LOG_IN,
            isLoggedInError: false,
          });
          await localStorage.setItem("token", token);
          await localStorage.setItem(
            "profile_pic",
            response.data.customer.profile_pic
          );
          await localStorage.setItem(
            "name",
            response.data.customer.login_name
          );
          await localStorage.setItem(
            "email",
            response.data.customer.email_address
          );
          await localStorage.setItem(
            "phone",
            response.data.customer.phone_number
          );
          await localStorage.setItem(
            "id",
            response.data.customer.customer_id
          );
          await localStorage.setItem("gender", response.data.customer.gender);
          await localStorage.setItem(
            "customer_birthday",
            response.data.customer.customer_birthday
          );
          await localStorage.setItem("profiledata", response.data.customer);
          window.location.href = "/"
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: LOG_IN,
            isLoggedInError: true,
          });
        } else {
        }
      });
  }
}
//update menu items from existing props
export const UpdateMenuItems = (data: any) => {
  return async (dispatch: any) => {
    console.log(data)
    dispatch({
      type: MENU_ITEMS,
      payload: data.menuItemsData,
      groupName: data.group_name,
    });
  }
}


export const saveGroupsData = (data: any) => {
  return async (dispatch: any) => {
    console.log(data)
    dispatch({
      type: UPDATE_GROUPS_LIST,
      groupsData: data,
    });
  }
}

//redirect customer to tab1 incase he leave component for forgot password
export const updateForgotTab = (tab: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: FORGOT,
      isReset: '',
      respMessage: '',
      switchTab: tab
    })
  }
}



export const getCardOrderDetail = (obj: any) => {
  return function (dispatch: any) {
    Api.post('/customer/card_order_detail', obj)
      .then((response) => {
        console.log("response data", response.data);
        if (response.data.success) {
          localStorage.setItem("orderId",response.data.successResponse.order_id)
          dispatch({
            type: CARD_ORDER_DETAIL,
            payload: response.data.successResponse,
          })
          
        }
      }).catch(err => {
        if (err.response) {
          // dispatch({
          //   type: Card_ORDER_DETAIL,
          //   payload: false,
          // })
          let error;
          if (typeof (err.response.data.message) === "string") {
            error = err.response.data.message;
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
  }
}
export const getTimezone = (id: number) => {
  return function (dispatch: any) {
    return Api.get(`/customer/state/timezone/${id}`)
      .then((response) => {
        console.log('response.data.successResponse', response.data.successResponse);

        if (response.data.success) {
          return response.data.successResponse
        }
      }).catch(err => {
        if (err.message) {
          alert(err.message)
        }
      });
  }
}

export const getTimezoneForLater = (id: number) => {
  return function (dispatch: any) {
    return Api.get(`/customer/state/timezone/${id}`)
      .then((response) => {
        console.log('response.data.successResponse', response.data.successResponse);

        if (response.data.success) {
          // dispatch({
          //   type: GET_TIMEZONE,
          //   payload: response.data.successResponse
          // })
          return { time: response.data.successResponse, date: response.data.successLaterResponse }
        }
      }).catch(err => {
        if (err.message) {
          alert(err.message)
        }
      });
  }
}

//location change modal flag to show and hide modal with message we dont deliver in your location
//redirect customer to tab1 incase he leave component for forgot password
export const locationChangeFlags = (flag: any) => {
  console.log(flag)
  return async (dispatch: any) => {
    dispatch({
      type: LOCATION_FLAG,
      payload: flag
    })
  }
}
export const saveAddress = (detail_address: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: SAVE_ADDRESS,
      payload:detail_address
    })
  }
}

export const PickupStoreList = () => {
  return async (dispatch: any) => {
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          let activeStores: any = [];
          Responedata.map((store: any) => {
            if (store.is_active == 1 && store.cloudKitchen == 'Disabled') {
              store.label = store.store_name;
              store.value = store.store_id;
              activeStores.push(store);
            }
          })
          dispatch({
            type: STORES_LIST,
            payload: activeStores,
            selectStoreId: data[0].store_id,
            deliveryfee: 0,
            storeStatus: 0, // 1 for out of Range,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // Actions.selectstore();
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};

//Get Coffee Loyalty Card Group
export const getCoffeeLoyaltyGroupAndCustomerCoffeeCount = () => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem("token");
    if (token) {
      let decoded: any = jwt_decode(token);
      let id: any = decoded.customer.customer_id;
      Api.get(`/simplex_loyalty/get_customer_coffee_count/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: GET_COFFEE_CARD_GROUP,
              payload: response.data.successResponse,
            });
            setTimeout(() => {
            }, 6000);
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: GET_COFFEE_CARD_GROUP,
              payload: [],
            });
          } else {
            // console.log("ok  Orderlist");
          }
        });
    }
  };
};

//Get SMOOTHIE Loyalty Card Group
export const getSmoothieLoyaltyGroupAndCustomerSmoothieCount = () => {
  return async (dispatch: any) => {
    let token: any = await localStorage.getItem("token");
    if (token) {
      let decoded: any = jwt_decode(token);
      let id: any = decoded.customer.customer_id;
      Api.get(`/simplex_loyalty/get_customer_smoothie_count/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          console.log(".then((response) => {", response);
          
          if (response.data.success) {
            dispatch({
              type: GET_SMOOTHIE_CARD_GROUP,
              payload: response.data.successResponse,
            })
            setTimeout(() => {
            }, 6000);
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: GET_SMOOTHIE_CARD_GROUP,
              payload: [],
            });
          } else {
            // console.log("ok  Orderlist");
          }
        });
    }
  };
};

//Get USER COFFEE COUNT
// export const getCustomerCoffeeCount = () => {
//   return async (dispatch: any) => {
//     let token: any = await localStorage.getItem("token");
//     if (token) {
//       let decodedData: any = jwt_decode(token);
//       let id: any = decodedData.customer.customer_id;
//       Api.get(`/simplex_loyalty/get_customer_coffee_count/${id}`, {
//         headers: { Authorization: "Bearer " + token },
//       })
//         .then((response) => {
//           debugger
//           if (response.data.success) {
//             // dispatch({
//             //   type: GET_SMOOTHIE_CARD_GROUP,
//             //   payload: response.data.successResponse,
//             // });
//           }
//         })
//         .catch((err) => {
//           if (err.response) {
//             // dispatch({
//             //   type: GET_SMOOTHIE_CARD_GROUP,
//             //   payload: [],
//             // });
//           } else {
//             // console.log("ok  Orderlist");
//           }
//         });
//     }
//   };
// };
