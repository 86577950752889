import React, { Component } from "react";
import { connect } from "react-redux";
import { getStores, searchStore } from "../../Redux";
import i18next from "i18next";
import { RestaurantlocatorState } from "../Interface/loginInterface";
import { Scrollbars } from "rc-scrollbars";
import Map from "./map";
import './resloc.css';
import { Link } from "react-router-dom";
class index extends Component<{ google?: any, restuarants: any[], searchStoresArray: any[], searchStores: (data: any, store_id: any) => {}, getStores: () => {} }, RestaurantlocatorState> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: '',
            stores: [],
            storeName: "All Stores",
            showDropDown: false
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getStores();
    }
    handleInputChange = (e:any) => {
        this.setState({ id: e.target.value });
        this.props.searchStores(this.props.restuarants, e.target.value)
    }
    setStoreId = (store: any) => {
        if(store === 'All') {
            this.setState({id: store, storeName: store})
        }
        else {
            this.setState({id: store.store_id, storeName: store.store_name});
        }
        this.props.searchStores(this.props.restuarants, store.store_id)
        this.setState({showDropDown: false})
    }   
    render() {
        const { restuarants, searchStoresArray } = this.props;
        return (
            <main className="main">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                    <div className="backBtn-wrapper">
                        <Link to={`/home${window.location.search}`}>
                            <div className="org-header__btn--circle">
                                <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg>
                            </div>
                        </Link>
                    </div>
                    </div>
                </nav>
                <div className="container">
                    <header id="center">
                        <h2 style={{fontSize: '24px'}}>{i18next.t('restaurantLocatorPage.restaurant_locator')}</h2>
                    </header>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dropdown">
                                <div onClick={() => {this.setState({showDropDown: !this.state.showDropDown})}} className="dropdown-select">
                                    <span>{this.state.storeName}</span>
                                    {!this.state.showDropDown ? 
                                    <i className="fa fa-caret-down icon"></i>
                                    : 
                                    <i className="fa fa-caret-up icon"></i>
                                    }
                                </div>
                                {this.state.showDropDown && 
                                <div className="dropdown-option-list">
                                    <div onClick={() => {this.setStoreId("All")}} className="dropdown-option-item">
                                        <span>All</span>
                                        </div>
                                    {restuarants &&
                                    restuarants.map((store, index) => (
                                        <div onClick={() => {this.setStoreId(store)}} className="dropdown-option-item">
                                        <span>{store.store_name}</span>
                                        </div>
                                    ))
                                    }
                                </div>
                                }
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <button type="submit" onClick={this.onSearch} style={{ borderRadius: 20 }} className="btn custom-default-red-button mt-1">{i18next.t('restaurantLocatorPage.search_btn')}</button>
                        </div> */}
                    </div>
                    <br />

                    <div className="row">
                        <div className="col-sm-4">
                            <Scrollbars style={(restuarants && restuarants.length > 0) ? { height: '45vh' } : { height: '20vh' }} >

                                {(restuarants && restuarants.length == 0) &&
                                    <label id="txtcolor" className="my-4 ml-3"> {i18next.t('restaurantLocatorPage.no_result')} </label>
                                }
                                {restuarants &&
                                    searchStoresArray.length > 0 ?
                                    searchStoresArray.map((store: any) => (
                                        <div>
                                            <label id="txtcolor">{store.store_name}</label>
                                            <p id="info">{store.address} </p>
                                            <p id="info">{store.city}</p>
                                            <br />
                                            {
                                                store.contact1 != null && <p id="info"> <label id="txtcolor">{i18next.t('restaurantLocatorPage.phone_number')}</label> {store.contact1}</p>

                                            }

                                            <hr className="new1" />
                                        </div>
                                    )) : restuarants.map((store: any) => (
                                        <div onClick={() => {this.setStoreId(store)}} className="stores-wrapper">
                                            <label id="txtcolor">{store.store_name}</label>
                                            <p id="info">{store.address} </p>
                                            <p id="info">{store.city}</p>
                                            <br />
                                            {
                                                store.contact1 != null && <p id="info"> <label id="txtcolor">{i18next.t('restaurantLocatorPage.phone_number')}</label> {store.contact1}</p>

                                            }

                                            <hr className="new1" />
                                        </div>
                                    ))
                                }
                            </Scrollbars>
                        </div>
                        <div className="col-sm-8">
                            <Map
                                data={searchStoresArray.length > 0 ? searchStoresArray : restuarants}
                                google={this.props.google}
                                height='300px'
                                zoom={10}
                            />
                        </div>
                    </div>

                    <br />
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        restuarants: state.login.restuarants,
        searchStoresArray: state.login.searchStores
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getStores: function () {
            dispatch(getStores());
        },
        searchStores: function (data: any, store_id: any) {
            dispatch(searchStore(data, store_id));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
