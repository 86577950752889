var ip = window.location.hostname;
var protocol = window.location.protocol == "https:" ? 'https' : 'http';
// var baseURL  = window.location.origin;
export const API_URL = `${protocol}://${ip}:3003/`;
// export const BASE_URL = `https://twebstg.simplexts.com.au:3003/`;
export const CALLBACK_URL = `${protocol}://${ip}`
export const BASE_URL = `${protocol}://${ip}:3003/`;
// export const BASE_URL_LOCAL = `http://192.168.18.8:3001/`;
// export const GoogleMapsAPI = '';  //for tortilla solution
export const StripeKey = 'pk_test_51KTiSOL6slnBJvxkNnyGl5tbA20HXxf5Ga1POZw75vsQxEbYoVskM9GPMO3nrcR9S1Z6sTNI2zTomyVdDlkYVS3F00AdhsGZGz' //for stripe testing account
// export const StripeKey = 'pk_live_51KTiSOL6slnBJvxkbuNUcKMw7hYUgp0Hwv9NqMNJ67vc5yoqQJH5qm2wRnlQ7FZvW0YauDxqBlteEpIf24Lp6ikl00n81VGJbr' //for live stripe account
export const GoogleMapsAPI = 'AIzaSyDTaA9BspUUJtGr_9oN7u5wEaKQIvq3XyE';  //for Simplex solution
export const priceunit = 'AED '
