import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import ProfileComponent from "../components/Login/profile";
import { Redirect } from "react-router-dom";

class Profile extends Component<{history:any},{}> {
  componentDidMount(){
    // document.title = "Profile - Tortilla";
    document.title = "Profile - More Cafe"
  }
  render() {
    // if (!localStorage.mainToken) {
    //   return <Redirect to="/landing" />
    // }
    return (
      <div className="page-wrapper">
        <div id="page-wrap">
          <Header history={this.props.history}/>
          <ProfileComponent />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Profile;
