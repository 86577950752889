import axios from "axios";
import { API_URL } from "./client-config";
export default axios.create({
  baseURL: API_URL
  // baseURL: "https://k2g-yum.simplexts.net:3000/",
  //  baseURL: "http://192.168.1.7:7000"
  // baseURL: "https://eathoswebstg.simplexts.com.au:3000/",
  // baseURL: "http://192.168.0.112:3001"
  // baseURL:"https://yum.krispy2go.com:3000/"
  // baseURL: "https://optpwebstg.simplexts.com.au:3000/"
  // baseURL: "https://optp.biz:3000/"
  // baseURL: "http://localhost:4009"
  // baseURL: "https://morecafewebstg.simplexts.com.au:3009"
});
