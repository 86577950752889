import React, { Component } from "react";
import { LocationProps, LocationState } from "../Interface/loginInterface";
import { Link } from "react-router-dom";
import "./styleCheckout.css";
import i18next from "i18next";
import Map from "./map";
import {
  findLocalStores,
  findPickupStores,
  getCart,
  saveCart,
  saveDeliveryFee,
  saveGuestAddress,
  saveHouseDetail,
  saveOrderType,
  saveSelectStoreId,
  saveStore,
  addressesListk,
  applyCoupon,
  setTabName,
  addContact
} from "../../Redux";
import { connect } from "react-redux";
import _ from "lodash";
import { Scrollbars } from "rc-scrollbars";
import Geocode from "react-geocode";
import { GoogleMapsAPI } from "../../ApiManager/client-config";
import { toast } from "react-toastify";
import {
  addAddressUser,
  SaveDiscountValue,
  SaveTaxValue,
  saveurlMethod,
  saveAddress,
  resetCoupon,
  saveAddressExtraDetails
} from "../../Redux/Actions";
import "react-responsive-modal/styles.css";
import { Button, Modal as BsModal } from "react-bootstrap";
import { BASE_URL, priceunit } from "../../ApiManager/client-config";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import GoogleMap from './newMap';
import jwtDecode from "jwt-decode";


Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

class index extends Component<LocationProps, LocationState> {
  // discount: number;
  cartTotal: any;
  itemTotal: any;
  tax: any;
  totalTax: any;
  discount: number;
  codeExeCount: number;
  couponDiscount: number;
  quantity: number;
  WindowObj: any;
  netWork = false;
  constructor(props: any) {
    super(props);

    this.state = {
      searchString: "",
      editableAddress: false,
      selectedAddress: "",
      lat: 0,
      lng: 0,
      firstname: "",
      lastname: "",
      email: "",
      isValidEmail: true,
      phonenumber: "971",
      laterdatetime: new Date(),
      contactNumber: "971",
      correctaddress: false,
      coupanCode: "",
      discountFlag: false,
      netWork: false,
      phone_netWork: false,
      cartTotal: 0,
      removeableLsmItemsArr: [],
      showHide: false,
      FlatdiscountFlag: false,
      searchAddress: "",
      position: {},
      placesSuggestion: [],
      country: "",
      city: "",
      state: "",
      area: "",
      removeableInvalidItemsArr: [],
      showValidteItemModal: false,
      buildingname: "",
      buildingnumber: "",
      room: "",
      description: "",
      changeLocation: false,
      area_details:''
    };
    this.itemTotal = 0;
    this.discount = 0;
    this.quantity = 0;
    this.totalTax = 0;
    this.codeExeCount = 0;
    this.couponDiscount = 0;
    this.handleChangeAddressPickup = this.handleChangeAddressPickup.bind(this);
    this.handleChangeCoupan = this.handleChangeCoupan.bind(this);
    this.selectCorrectAddress = this.selectCorrectAddress.bind(this);
    this.setDeliveryAddress = this.setDeliveryAddress.bind(this);
    this.addContact = this.addContact.bind(this);
    this.GoToProfile = this.GoToProfile.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setTabName("location");
    this.props.saveGuestAddress("");
    this.props.saveAddressExtraDetails('')
    const token = localStorage.getItem("token");
    let cart: any = await sessionStorage.getItem("cart");
    let orderType: any = await sessionStorage.getItem("orderType");
    let storeSelected: any = await sessionStorage.getItem("selectedStore");
    if (!orderType) {
      this.props.history && this.props.history.push("/")
    }
    if (orderType == 'Pickup' || orderType == 'qr-Pickup') {
      let pickupStore: any = JSON.parse(storeSelected);
      this.props.saveOrderType(orderType)
      this.props.saveStore(pickupStore)
      this.props.history && this.props.history.push({pathname: "/payment" , search: window.location.search})
      this.props.setTabName("payment")
    }
    let data = JSON.parse(cart);
    if (data === null || data.length <= 0) {
      cart = [];
    }
    if (cart.length == 0) {
      this.props.history && this.props.history.push({pathname: "/cart", search: window.location.search})
    }
    if (token) {
      this.props.addressesListk();
    } else {
      this.props.history.push({pathname: "/auth", search: window.location.search})
    }
    if (this.props.address_save !== "") {
      this.props.saveGuestAddress(this.props.address_save);
    }
    if(this.props.extra_address_details != '') {
      this.props.saveAddressExtraDetails(this.props.extra_address_details)
    }
    
    if (this.props.orderType === 'Pickup') {
      this.props.history.push({pathname: "/payment", search: window.location.search})
      this.props.setTabName("payment")
    }
    this.validateCart();
    this.ValidateItemForStore();

  }
  ApplyCoupan = async () => {
    let { coupanCode, discountFlag } = this.state;
    let { orderType, cart, coupon } = this.props;
    this.codeExeCount = 0;
    let Cart: any = cart;
    let discountItemFlag = false;
    let itemTotal;
    console.log("coupon function");
    let dataCart = JSON.parse(Cart);
    dataCart.forEach((item: any) => {
      itemTotal = item.totalItemPrice
      if (item.combo) {
        if (item.combo.discount_price) {
          this.setState({ discountFlag: true });
          discountItemFlag = true;
        }
      } else {
        if (item.selectedsize.discount_price) {
          this.setState({ discountFlag: true });
          discountItemFlag = true;
        }
      }
    });
    if (coupanCode !== "" && discountItemFlag == false) {
      let token: any = localStorage.getItem("token");
      let decodedData: any = jwtDecode(token);
      this.props.getCart();
      let storeSelected: any = await sessionStorage.getItem("selectedStore");
      let data = {
        customer_id: decodedData.customer.customer_id,
        store_id: storeSelected ? JSON.parse(storeSelected).store_id : null,
        coupon_code: coupanCode,
        order_mode: "online",
        order_channel: orderType == "Delivery" ? "delivery" : "pickup",
      };
      if (coupon.min_amount > JSON.stringify(itemTotal)) {
        toast.error("Coupon can not as cart total is less than cooupon min total value");
        this.props.resetCoupon({});
      }
      this.props.applyCoupon(data);
      this.calcTotal(dataCart);
    } else {
      toast.error("Coupon can not be applied on discounted items");
    }
  };

  isCoupanReady = () => {
    let { coupanCode } = this.state;
    let { orderType, cart } = this.props;
    return coupanCode !== "";
  };
  islocationReady = () => {
    let {
      address_save,
      houseDetail,
      // streetDetail,
      addressType,
      orderType,
      selectStoreId,
      stores,
    } = this.props;
    let { buildingname, description, room } = this.state;
    let addressList: any = [];
    addressList = this.props.addressData;
    let tempFlag = true;
    if (orderType == "Delivery") {
      if (stores.length > 0) {
        tempFlag = true;
      }
      // return stores.length > 0;
    } else if (orderType == "Pickup") {
      if (stores.length > 0) {
        tempFlag = true;
      }
      // return stores.length > 0;
    }
    if (address_save !== '' && addressList.length > 0) {
      tempFlag = true;
    }
    if(this.state.changeLocation) {
      if(room == '') {
        tempFlag = false;
      }
      else if (this.state.area_details == '') {
        tempFlag = false;
      }
    }
    if(address_save == '') {
      tempFlag = false
    }
    return tempFlag
  };
  handleClose = () => {
    this.setState({ showHide: false });
  };
  handleChangeAddressPickup(event: any) {
    this.setState({ searchString: event.target.value });
  }
  checkAddress() {
    let { addressData } = this.props;

    if (addressData?.length == 0) {
      let obj = {
        place: "Home",
        full_address: this.props.address_save,
        extra_details:this.state.area_details + " " + this.state.buildingname + ' ' + this.state.room
      };
      this.props.addAddress(obj);
      this.props.saveAddressExtraDetails(obj.extra_details)
      return;
    } else if (this.state.changeLocation) {
      let obj = {
        place: this.state.description !== '' ? this.state.description : 'Other',
        full_address: this.props.address_save,
        extra_details:this.state.area_details + " " + this.state.buildingname + ' ' + this.state.room
      }
      this.props.addAddress(obj)
      this.props.saveAddressExtraDetails(obj.extra_details)
      return
    }
  }
  removeLsmItems = () => {
    let { removeableLsmItemsArr } = this.state;
    const token = localStorage.getItem("token");
    let data: any = this.props.cart;
    let cart: any = JSON.parse(data);
    let dupCart: any = cart;
    for (let i = 0; i < cart.length; i++) {
      for (let j = 0; j < removeableLsmItemsArr.length; j++) {
        if (cart[i].item && removeableLsmItemsArr[j].item) {
          if (
            cart[i].item.menu_item_id ===
            removeableLsmItemsArr[j].item.menu_item_id
          ) {
            dupCart.splice(i, 1);
          }
        } else if (cart[i].combo && removeableLsmItemsArr[j].combo) {
          if (
            cart[i].combo.combo_id === removeableLsmItemsArr[j].combo.combo_id
          ) {
            dupCart.splice(i, 1);
          }
        }
      }
    }
    this.props.saveCart(dupCart);
    this.props.getCart();
    this.setState({ showHide: false });
    if (cart.length === 0) {
      this.props.history.push({pathname: "cart", search: window.location.search});
    } else {
      if (token) {
        window.scrollTo(0, 0);
        this.props.setTabName("payment");
      } else {
        window.scrollTo(0, 0);
        this.props.setTabName("details");
      }
      if (this.props.orderType == "Pickup") {
        this.props.saveGuestAddress("");
        this.props.saveAddressExtraDetails('')
        this.props.saveHouseDetail("");
      }
      if (cart.length === 0) {
        this.props.history.push({pathname: "cart", search: window.location.search});
      }
    }
  };
  removeInvalidCartItem = () => {
    let { removeableInvalidItemsArr } = this.state;
    let data: any = this.props.cart;
    let cart: any = JSON.parse(data);
    let dupCart: any = cart;
    for (let i = 0; i < cart.length; i++) {
      for (let j = 0; j < removeableInvalidItemsArr.length; j++) {
        if (cart[i] && cart[i].item && removeableInvalidItemsArr[j].item) {
          if (
            cart[i].selectedsize.id ===
            removeableInvalidItemsArr[j].selectedsize.id
          ) {
            dupCart.splice(i, 1);
          }
        } else if (
          cart[i] &&
          cart[i].combo &&
          removeableInvalidItemsArr[j].combo
        ) {
          if (
            cart[i].combo.combo_id ===
            removeableInvalidItemsArr[j].combo.combo_id
          ) {
            dupCart.splice(i, 1);
          }
        }
      }
    }
    this.props.saveCart(dupCart);
    this.props.getCart();
    this.setState({ showValidteItemModal: false });
    if (cart.length === 0) {
      this.props.history.push({pathname: "/cart", search: window.location.search});
    }
  };
  ValidateItemForStore = () => {
    let selectedStore: any = sessionStorage.getItem("selectedStore")
    let { pickupStoresList,menuStoreId } = this.props;
    let result = pickupStoresList.find((x: any) => x.store_id == menuStoreId);
    let obj = {
      mode: "online",
      store_id: result && result.store_id ? result.store_id : JSON.parse(selectedStore).store_id
    };
    axios.post(`${BASE_URL}menu/getItemsForSpecificStore`, obj)
      .then((res) => {
        let specificStoreItems = res.data.menuData;
        let data: any = this.props.cart;
        let cart: any = JSON.parse(data);
        let dupCart: any = cart;
        let lsmItemRemove: any = [];
        for (let i = 0; i < cart.length; i++) {
          let found = 0;
          if (cart[i].item) {
            if (specificStoreItems.length > 0) {
              for (let j = 0; j < specificStoreItems.length; j++) {
                if (specificStoreItems[j].menu_item_id > 0) {
                  if (
                    cart[i].item.menu_item_id ==
                    specificStoreItems[j].menu_item_id
                  ) {
                    found = 1;
                    break;
                  }
                }
              }
              if (found == 0) {
                lsmItemRemove.push(cart[i]);
                this.setState({ removeableLsmItemsArr: lsmItemRemove });
              }
            } else {
              lsmItemRemove.push(cart[i]);
              this.setState({ removeableLsmItemsArr: lsmItemRemove });
            }
          } else {
            if (specificStoreItems.length > 0) {
              for (let j = 0; j < specificStoreItems.length; j++) {
                if (
                  cart[i].combo.combo_id == specificStoreItems[j].combo_id
                ) {
                  found = 1;
                  break;
                }
              }
              if (found == 0) {
                lsmItemRemove.push(cart[i]);
                this.setState({ removeableLsmItemsArr: lsmItemRemove });
              }
            } else {
              lsmItemRemove.push(cart[i]);
              this.setState({ removeableLsmItemsArr: lsmItemRemove });
            }
          }
        }
        if (lsmItemRemove.length > 0) {
          this.setState({ showHide: true });
        } 
      })
      .catch((err) => {
        alert("something went wrong");
      });
  }
  validateCart = () => {
    console.log("optp store id", this.props.storeSelected)
    let data: any = sessionStorage.getItem("cart");
    let selectedStore: any = sessionStorage.getItem("selectedStore")
    let cart: any = JSON.parse(data);
    let obj = {
      cart: cart,
      mode: "online",
      store_id: this.props.storeSelected.store_id ? this.props.storeSelected.store_id : JSON.parse(selectedStore).store_id
    };
    axios
      .post(`${BASE_URL}menu/validateCart`, obj)
      .then((res) => {
        let invalidCart = res.data.menuData;
        let InvalidItemRemove: any = [];
        for (let i = 0; i < cart.length; i++) {
          for (let j = 0; j < invalidCart.length; j++) {
            if (cart[i].item && invalidCart[j].item) {
              if (cart[i].selectedsize.id === invalidCart[j].item.variant_id) {
                InvalidItemRemove.push(cart[i]);
                this.setState({ removeableInvalidItemsArr: InvalidItemRemove });
              }
            } else if (cart[i].combo && invalidCart[j].combo) {
              if (cart[i].combo.combo_id === invalidCart[j].combo.combo_id) {
                InvalidItemRemove.push(cart[i]);
                this.setState({ removeableInvalidItemsArr: InvalidItemRemove });
              }
            }
          }
        }
        this.setState({ showValidteItemModal: true });
      })
      .catch((err) => {
        this.setState({ showValidteItemModal: false });
      });
  };
  saveStoreData = async () => {
    this.saveAddressDetails()
    let { stores, selectStoreId, menuStoreId } = this.props;
    let { removeableLsmItemsArr } = this.state;
    let selectedStore: any = sessionStorage.getItem("selectedStore")
    const token = localStorage.getItem("token");
    if (this.props.address_save == "" && this.props.orderType == "Delivery") {
      toast.error(i18next.t("errorMsg.allowGPS"));
      return;
    }
    this.checkAddress();
    let result = stores.find((x: any) => x.store_id == selectStoreId); //find the store from the stores list
    if (menuStoreId !== selectStoreId) {
      let obj = {
        mode: "online",
        store_id: this.props.storeSelected.store_id ? this.props.storeSelected.store_id : JSON.parse(selectedStore).store_id
      };
      this.setState({ netWork: true });
      axios
        .post(`${BASE_URL}menu/getItemsForSpecificStore`, obj)
        .then((res) => {
          let specificStoreItems = res.data.menuData;
          let data: any = this.props.cart;
          let cart: any = JSON.parse(data);
          let dupCart: any = cart;
          let lsmItemRemove: any = [];
          for (let i = 0; i < cart.length; i++) {
            let found = 0;
            if (cart[i].item) {
              if (specificStoreItems.length > 0) {
                for (let j = 0; j < specificStoreItems.length; j++) {
                  if (specificStoreItems[j].menu_item_id > 0) {
                    if (
                      cart[i].item.menu_item_id ==
                      specificStoreItems[j].menu_item_id
                    ) {
                      found = 1;
                      break;
                    }
                  }
                }
                if (found == 0) {
                  lsmItemRemove.push(cart[i]);
                  this.setState({ removeableLsmItemsArr: lsmItemRemove });
                }
              } else {
                lsmItemRemove.push(cart[i]);
                this.setState({ removeableLsmItemsArr: lsmItemRemove });
              }
            } else {
              if (specificStoreItems.length > 0) {
                for (let j = 0; j < specificStoreItems.length; j++) {
                  if (
                    cart[i].combo.combo_id == specificStoreItems[j].combo_id
                  ) {
                    found = 1;
                    break;
                  }
                }
                if (found == 0) {
                  lsmItemRemove.push(cart[i]);
                  this.setState({ removeableLsmItemsArr: lsmItemRemove });
                }
              } else {
                lsmItemRemove.push(cart[i]);
                this.setState({ removeableLsmItemsArr: lsmItemRemove });
              }
            }
          }
          if (lsmItemRemove.length > 0) {
            this.setState({ showHide: true });
          } else {
            let paramsData = {
              city: this.state.city,
              country: this.state.country,
              state: this.state.state,
            };
            window.scrollTo(0, 0);
            this.props.history.push("/payment", { data: paramsData });
            this.checkoutDataLayer("Payment");
            this.props.setTabName("payment");
            //Code to Guest Checkout
            // if (token) {
            //     window.scrollTo(0, 0);
            //     this.props.history.push('/payment', { data: paramsData })
            //     this.checkoutDataLayer("Payment")
            //     this.props.setTabName("payment");
            // } else {
            //     window.scrollTo(0, 0);
            //     this.props.history.push('/guestDetails', { data: paramsData })
            //     this.checkoutDataLayer("GuestDetails")
            //     this.props.setTabName("details");
            // }
            if (this.props.orderType == "Pickup") {
              this.props.saveGuestAddress("");
              this.props.saveAddressExtraDetails('')
              this.props.saveHouseDetail("");
            }
            this.props.saveStore(result)
          }
          this.setState({ netWork: false });
        })
        .catch((err) => {
          alert("something went wrong");
          this.setState({ netWork: false });
        });
    } else {
      let paramsData = {
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
      };
      window.scrollTo(0, 0);
      this.props.history.push("/payment", { data: paramsData });
      this.checkoutDataLayer("Payment");
      this.props.setTabName("payment");
      //Code to Guest Checkout
      // if (token) {
      //     window.scrollTo(0, 0);
      //     this.props.history.push('/payment', { data: paramsData })
      //     this.checkoutDataLayer("Payment")
      //     this.props.setTabName("payment");
      // } else {
      //     window.scrollTo(0, 0);
      //     this.props.history.push('/guestDetails', { data: paramsData })
      //     this.checkoutDataLayer("GuestDetails")
      //     this.props.setTabName("details");
      // }
      if (this.props.orderType == "Pickup") {
        this.props.saveGuestAddress("");
        this.props.saveAddressExtraDetails('')
        this.props.saveHouseDetail("");
      }
    }
    // else{
    //   this.props.saveGuestAddress("");
    //   this.props.saveHouseDetail("");
    // }
    this.props.saveStore(result);
    // this.props.saveDeliveryFee(result.delivery_fee);
    // this.props.saveDateTime({ date: date, time: time });
  };

  checkoutDataLayer = (step: any) => {
    let data: any = this.props.cart;
    let cart: any = JSON.parse(data);
    let products: any = [];
    let MapObj: any = {};
    cart.forEach((cartItem: any) => {
      if (cartItem.item) {
        MapObj = {
          quantity: cartItem.quantity,
          price: cartItem.totalItemPrice,
          id: cartItem.item.menu_item_id,
          name: cartItem.item.item_name,
          variant: cartItem.selectedsize.size,
        };
      } else if (cartItem.combo) {
        MapObj = {
          quantity: cartItem.quantity,
          price: cartItem.totalItemPrice,
          id: cartItem.combo.combo_id,
          name: cartItem.combo.combo_name,
        };
      }
      products.push(MapObj);
    });

    let windowObj = window as any;
    windowObj.dataLayer.push({ ecommerce: null });
    windowObj.dataLayer.push({
      event: "checkout",
      ecommerce: {
        currencyCode: "AED",
        checkout: {
          actionField: { step: step, option: "Cash" },
          products: products,
        },
      },
    });
  };

  handleOnChange = (value: any, data: any) => {
    this.setState({ phonenumber: value });
  };
  handleChangeCoupan(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  selectCorrectAddress() {
    this.setState({ correctaddress: !this.state.correctaddress });
    this.props.saveHouseDetail("");
  }
  handleOnChangeOutbound = (value: any, data: any) => {
    this.setState({ contactNumber: value });
  };
  handleStores = () => {
    this.props.findPickupStores(
      this.state.lat,
      this.state.lng,
      this.state.searchString
    );
  };
  addContact() {
    this.setState({ phone_netWork: true });
    let str_phone_number = this.state.contactNumber;
    // if(str_phone_number.charAt(0) === '9'){
    //   str_phone_number = str_phone_number.substring(2);
    //   str_phone_number = "0"+str_phone_number
    // }
    let obj = {
      contact_number: str_phone_number,
      address: this.props.address_save,
    };
    this.props.addContact(obj);
  }
  handleMapData = (obj: any) => {
    //to Get the values from the map component and set in state
    this.props.saveGuestAddress(obj.address);
    this.props.findLocalStores(obj.lat, obj.lng);
    this.setState({
      selectedAddress: "",
      lat: obj.lat,
      lng: obj.lng,
      city: obj.city,
      searchString: obj.address,
      area: obj.area,
      country: obj.country,
      state: obj.state,
      position: {lat: obj.lat,lng: obj.lng}
    });
  };
  checkPhone = () => {
    const phone = localStorage.getItem("phoneNumber");
    if (phone) {
      // var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
      // var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/;
      var phoneno =
        /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{7}$/; // uae mobile numbers
      if (phone.match(phoneno)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  checkPhoneForOutBound = () => {
    const phone = this.state.contactNumber;
    if (phone !== "") {
      // var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
      // var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/;
      var phoneno =
        /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{7}$/; // uae mobile numbers
      if (phone.match(phoneno)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  GoToProfile() {
    this.props.saveurlMethod("updateprofile");
    this.props.history.push({pathname: "/profile", search: window.location.search});
    // window.location.href="/profile"
  }

  handleInputChange = (event: any) => {
    let queryString = event.target.value;
    this.setState({ searchString: event.target.value });
    if (queryString.length > 0 && queryString.length < 50) {
      let obj = {
        address: queryString,
      };
      const lang = localStorage.getItem("lang") || "en";
      axios
        .post(`${BASE_URL}customer/autoComplete/${lang}/${queryString}`)
        .then((res: any) => {
          this.setState({ placesSuggestion: res.data.successResponse });
        })
        .catch((err: any) => {
          alert("something went wrong!!!");
        });
    } else {
      this.setState({ placesSuggestion: [] });
    }
  };

  handleAddressInputChange = (event: any) => {
    if (event.target.value == ' ') {
      event.target.value = event.target.value.replace(/\s/g, "");
    }
    this.setState({ [event.target.name]: event.target.value });
  };
  saveAddressDetails = () => {
    const { buildingname, room, description, buildingnumber,area_details } = this.state
    this.props.saveAddress(description + " " + buildingname + " " + buildingnumber + " " + room + " " + area_details)
  }
  onPlaceClick = async (place: any) => {
    this.setState({
      searchString: place.structured_formatting.main_text,
      buildingname: "",
      buildingnumber: "",
      room: "",
      area_details:'',
      description: "",
    });
    Geocode.fromAddress(place.description)
      .then((json: any) => {
        var location = json.results[0].geometry.location;
        console.log(location);
        this.setState({
          lat: location.lat,
          lng: location.lng,
          placesSuggestion: [],
          position: { lat: location.lat, lng: location.lng },
        });
      })
      .catch((error: any) => {
        // console.warn(error));
      });
  };
  getCurrentPosition = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        this.setState({
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      });
    }
  };

  removeSearchString = () => {
    this.setState({ searchString: "", placesSuggestion: [] });
  };
  DeliveryFunction = () => {
    let { stores, orderType, addressData } = this.props;
    const lang = localStorage.getItem("lang") || "en";
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {this.manageAddressFun()}
            {(this.state.changeLocation || this.props.addressData.length == 0) &&
            <> 
            <div className="name-deliveryCart">
              <h3 className="custom-default-fonts-color">
                {i18next.t("checkoutPage.delivery_location")}
              </h3>
            </div>
            <div className="search-wrapper">
              <input
                placeholder={lang == "en" ? "Enter your area" : "اكتب عنوانك"}
                autoComplete="off"
                name="autocomplete_query"
                value={this.state.searchString}
                className="autocomplete-input autoComplete"
                onChange={this.handleInputChange}
              />
              {this.state.placesSuggestion.length > 0 && (
                <div className="address-suggestion">
                  <ul>
                    {this.state.placesSuggestion.map((place: any) => {
                      return (
                        <>
                          <li
                            onClick={() => this.onPlaceClick(place)}
                            className="suggestion-item"
                          >
                            <span className="upper-text">
                              <strong>
                                {place.structured_formatting.main_text}
                              </strong>
                            </span>
                            <span className="bottom-text">
                              {place.structured_formatting.secondary_text}
                            </span>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              )}
              {this.state.placesSuggestion &&
                this.state.placesSuggestion.length > 0 && (
                  <i
                    className="fa fa-times"
                    style={{ width: "20px", fontSize: "18px" }}
                    onClick={this.removeSearchString}
                    aria-hidden="true"
                  ></i>
                )}
              <img
                style={{ width: "20px", height: "20px" }}
                className=""
                title="Current Location"
                src="assets/images/gps.png"
                alt="pin"
                onClick={() => {
                  this.getCurrentPosition();
                }}
              />
            </div>

            {/* <Map
              // data={this.props.address_save !== "" ? data : ""}
              onSetData={this.handleMapData}
              location={this.state.position}
              google={this.props.google}
              height="300px"
              zoom={17}
            /> */}

            <GoogleMap
              onSetData={this.handleMapData}
              location={this.state.position}
            />
            </>
          }

            {/* {stores.length !== 0 && (
              <div className="input-addressCart">
                 <input
                  style={{ height: "20px" }}
                  type="checkbox"
                  {...(this.state.correctaddress == true && { checked: true })}
                  onChange={this.selectCorrectAddress}
                />
                <label
                  style={{
                    fontSize: "15px",
                    marginLeft: "10px",
                    marginTop: "-3px",
                  }}
                >
                  {i18next.t("checkoutPage.enter_house_detail")}
                </label> 
              </div>
            )}

            {stores.length !== 0 && this.state.correctaddress == true && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group required-field">
                    <label>
                      {i18next.t("checkoutPage.house_street_address")}
                    </label>
                     <input
                      style={{
                        fontSize: "16px",
                        borderRadius: "15px",
                        border: "1px solid grey",
                      }}
                      type="text"
                      value={this.props.houseDetail}
                      placeholder="Enter Your Details"
                      className="form-control"
                      onChange={(e) => {
                        this.props.saveHouseDetail(e.target.value);
                      }}
                    /> 
                  </div>
                </div>
              </div>
            )} */}

            {orderType == "Delivery" && stores.length === 0 &&  (
              <div className="row">
                <div className="col-md-10">
                  <h5
                    style={{
                      color: "black",
                      paddingBottom: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    {i18next.t("checkoutPage.out_of_coverage_title")}
                  </h5>
                  <p>Please enter your phone number with (5xx-xxx-xxx)</p>
                  <PhoneInput
                    inputProps={{
                      name: "fname",
                      required: true,
                      autoFocus: true,
                    }}
                    country={"ae"}
                    disableDropdown={true}
                    onlyCountries={["ae"]}
                    countryCodeEditable={false}
                    // // masks={{pk: ' (...) ..-..-...',}}
                    // // prefix={'+'}
                    // placeholder="3..-......."
                    value={this.state.contactNumber}
                    onChange={this.handleOnChangeOutbound}
                  />
                  <p>{i18next.t("checkoutPage.outside_delivery_area")}</p>
                  <div className="d-flex justify-content-between">
                    {this.state.phone_netWork == false ? (
                      <button
                        id="roundb"
                        type="button"
                        onClick={this.addContact}
                        disabled={
                          this.state.contactNumber !== "" &&
                            this.checkPhoneForOutBound()
                            ? false
                            : true
                        }
                        className="btn btn-sm custom-default-red-button"
                      >
                        {i18next.t("common.submit")}
                      </button>
                    ) : (
                      <button
                        disabled={this.state.phone_netWork}
                        id="roundb"
                        className="btn btn-sm custom-default-red-button"
                      >
                        <i className="fa fa-spinner fa-spin"></i>
                        {i18next.t("checkoutPage.loading")}
                      </button>
                    )}
                    <div style={{ color: "red" }}>
                      {i18next.t("checkoutPage.change_location")}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {((this.state.changeLocation && stores.length>0) || (this.props.addressData.length == 0 && stores.length>0)) && (
          <>
            {/* <div className="row mtop">
              <div className="form-group col-md-6 ">
                <h5>
                  {"City"} <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  className="form-control h-4rem"
                  name="city"
                  value={this.state.city}
                  onChange={(e) => {
                    this.handleAddressInputChange(e);
                  }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <h5>
                  {"Area"} <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  value={this.state.area}
                  className="form-control h-4rem"
                  name="area"
                  onChange={(e) => {
                    this.handleAddressInputChange(e);
                  }}
                  required
                />
              </div>
            </div> */}
            <div className="row mtop">
            <div className="form-group col-md-6">
                <h5>
                  {"Area"}{" "}
                  <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  className="form-control h-4rem"
                  name="area_details"
                  value={this.state.area_details}
                  // placeholder="Villa/Flat #"
                  onChange={(e) => {
                    this.handleAddressInputChange(e);
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <h5>
                  {"Building Name/Building Number"}{" "}
                  {/* <sup style={{ color: "red", fontSize: "12px" }}>*</sup> */}
                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  className="form-control h-4rem"
                  name="buildingname"
                  // placeholder="Building Name/Building Number"
                  value={this.state.buildingname}
                  required
                  onChange={(e) => {
                    this.handleAddressInputChange(e);
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <h5>
                  {"Villa/Flat No."}{" "}
                  <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  className="form-control h-4rem"
                  name="room"
                  value={this.state.room}
                  // placeholder="Villa/Flat #"
                  onChange={(e) => {
                    this.handleAddressInputChange(e);
                  }}
                />
              </div>
              
              {/* <div className="form-group col-md-6">
                <h5>
                  {"Building No."}{" "}

                </h5>
                <input
                  id="roundb"
                  style={{ fontSize: "15px" }}
                  type="text"
                  className="form-control h-4rem"
                  name="buildingnumber"
                  value={this.state.buildingnumber}
                  placeholder="Building Number"
                  required
                  onChange={(e) => {
                    this.handleAddressInputChange(e);
                  }}
                />
              </div> */}
            </div>
            <div className="row">

              <div className="form-group col-md-12">
                <h5>
                  {"Description"}{" "}
                  <sup style={{ color: "red", fontSize: "12px" }}></sup>
                </h5>
                <div className="cart-table-container">
                  <div className="iwant-sec">
                    <ul className="iwnt-toggleButton seconds">
                      <li onChange={() => this.setState({ description: "Home" })}>
                        <input
                          type="radio"
                          id="home"
                          name="drone"
                          value="Home"
                          {...(this.state.description == "Home" && {
                            checked: true,
                          })}
                          style={{ marginRight: "15px" }}
                        />
                        <label
                          htmlFor="home"
                          className="my-1"
                          style={{ fontSize: "12px" }}
                        >
                          {i18next.t('manageAddress.home')}
                        </label>
                      </li>
                      <li onChange={() => this.setState({ description: "Office" })}>
                        <input
                          type="radio"
                          id="office"
                          name="drone"
                          {...(this.state.description == "Office" && {
                            checked: true,
                          })}
                          style={{ marginRight: "15px" }}
                        />
                        <label
                          htmlFor="office"
                          className="my-1"
                          style={{ fontSize: "12px" }}
                        >
                          {i18next.t('manageAddress.office')}
                        </label>
                      </li>
                      <li onChange={() => this.setState({ description: "Other" })}>
                        <input
                          type="radio"
                          id="other"
                          name="drone"
                          {...(this.state.description == "Other" && {
                            checked: true,
                          })}
                          style={{ marginRight: "15px" }}
                        />
                        <label
                          htmlFor="other"
                          className="my-1"
                          style={{ fontSize: "12px" }}
                        >
                          {i18next.t('manageAddress.other')}
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

          </>
        )}
        <div className="row">
          {orderType == "Delivery" && stores.length > 0 && (
            <div className="col-md-12">
              <div className="checkout-steps-action d-flex justify-content-center">
                {this.state.netWork == false ? (
                  <button
                    {...(this.checkPhone()
                      ? { onClick: this.saveStoreData }
                      : { "data-toggle": "modal" })}
                    {...(this.checkPhone() == false && {
                      "data-target": `#activeInactive`,
                    })}
                    id="roundb"
                    className="btn custom-default-red-button float-right"
                    disabled={
                      !this.islocationReady() 
                    }
                  >
                    {/* continue test */}
                    {i18next.t("checkoutPage.continue")}
                  </button>
                ) : (
                  <button
                    disabled={this.state.netWork}
                    id="roundb"
                    className="btn custom-default-red-button float-right"
                  >
                    <i className="fa fa-spinner fa-spin"></i>
                    {i18next.t("checkoutPage.loading")}
                  </button>
                )}
              </div>
            </div>
          )}
          <BsModal backdrop="static" show={this.state.showHide} >
            <BsModal.Header closeButton>
              <BsModal.Title>
                {i18next.t("checkoutPage.items_not_available")}
              </BsModal.Title>
            </BsModal.Header>
            <BsModal.Body>
              <table className="table">
                {this.state.removeableLsmItemsArr &&
                  this.state.removeableLsmItemsArr.map((item: any) => (
                    <tbody>
                      <tr className="product-row">
                        {/* <td>{fav.combo_id ? fav.combo_id : fav.menu_item_id}</td> */}
                        <td className="product-col">
                          <figure className="product-image-container">
                            <a className="product-image">
                              <img
                                src={`${item.selectedsize
                                  ? item.selectedsize.image_url
                                  : item.combo.image_url
                                  }`}
                                alt="product"
                                style={{ width: "8rem" }}
                              />
                            </a>
                          </figure>
                        </td>
                        <td>
                          {item.item && item.item.item_name}
                          {item.combo && item.combo.combo_name}
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </BsModal.Body>
            <BsModal.Footer className="justify-content-center">
              <Button
                className="btn custom-default-red-button"
                onClick={this.removeLsmItems}
              >
                {i18next.t("checkoutPage.confirm")}
              </Button>
            </BsModal.Footer>
          </BsModal>
          <BsModal backdrop="static" show={this.state.showValidteItemModal}>
            <BsModal.Header closeButton>
              <BsModal.Title>
                These items are invalid and will be removed from the cart.
              </BsModal.Title>
            </BsModal.Header>
            <BsModal.Body>
              <table className="table">
                {this.state.removeableInvalidItemsArr &&
                  this.state.removeableInvalidItemsArr.map((item: any) => (
                    <tbody>
                      <tr className="product-row">
                        {/* <td>{fav.combo_id ? fav.combo_id : fav.menu_item_id}</td> */}
                        <td className="product-col">
                          <figure className="product-image-container">
                            <a className="product-image">
                              <img
                                src={`${item.selectedsize
                                  ? item.selectedsize.image_url
                                  : item.combo.image_url
                                  }`}
                                alt="product"
                                style={{ width: "8rem", borderRadius: 5 }}
                              />
                            </a>
                          </figure>
                        </td>
                        <td>
                          {item.item && item.item.item_name}
                          {item.combo && item.combo.combo_name}
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </BsModal.Body>
            <BsModal.Footer>
              <Button
                className="btn cart-button"
                style={{ borderRadius: 5 }}
                onClick={this.removeInvalidCartItem}
              >
                CONFIRM
              </Button>
            </BsModal.Footer>
          </BsModal>
          <div
            id={"activeInactive"}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            className="modal fade text-left"
          >
            <div role="document" className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="exampleModalLabel" className="modal-title">
                    {i18next.t("errorMsg.invalid_phone")}
                  </h4>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{i18next.t("checkoutPage.invalid_phone_text")}</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-danger"
                  >
                    {i18next.t("common.close")}
                  </button>
                  <button
                    onClick={this.GoToProfile}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    {i18next.t("common.ok")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  PickupFunction = () => {
    let { stores } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="name-deliveryCart label">
              <h3 style={{ color: "#4b2852" }}>
                {i18next.t("checkoutPage.enter_city")}
              </h3>
            </div>
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="input-addressCart">
                  <input
                    style={{ outline: "none" }}
                    id="roundPick"
                    placeholder="Please Enter"
                    type="text"
                    value={this.state.searchString}
                    onChange={this.handleChangeAddressPickup}
                  />
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => this.handleStores()}
                    style={{ borderRadius: 10, padding: 15 }}
                  >
                    <i
                      className="fa fa-arrow-right next-d"
                      style={{ margin: 2 }}
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
              <div className="col-lg-3"></div>
            </div>

            <div className="storeName-cartD">
              <div>
                {stores && stores.length > 0
                  ? stores.map((store, index) => (
                    <div
                      className="storeShow-cart"
                      onClick={() => this.props.saveSelectStoreId(store)}
                    >
                      <input
                        type="radio"
                        id="pick"
                        name="store"
                        value="Pickup"
                        {...(this.props.selectStoreId == store.store_id && {
                          checked: true,
                        })}
                        style={{ marginRight: "15px" }}
                      />
                      <p style={{ fontSize: "15px", color: "black" }}>
                        {store.store_name} <br />
                        {store.address}, {store.city}
                      </p>
                    </div>
                  ))
                  : null}
              </div>
            </div>
            {stores && stores.length == 0 ? (
              <div className="row">
                <div className="col-md-10">
                  <h5
                    style={{
                      color: "black",
                      paddingBottom: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    {i18next.t("checkoutPage.out_of_coverage_title")}
                  </h5>
                  <p>Please enter your phone number with (5xx-xxx-xxx)</p>
                  <PhoneInput
                    inputProps={{
                      name: "fname",
                      required: true,
                      autoFocus: true,
                    }}
                    country={"ae"}
                    disableDropdown={true}
                    onlyCountries={["ae"]}
                    countryCodeEditable={false}
                    // // masks={{pk: ' (...) ..-..-...',}}
                    // // prefix={'+'}
                    // placeholder="3..-......."
                    value={this.state.contactNumber}
                    onChange={this.handleOnChangeOutbound}
                  />
                  <p className="noteK">
                    {i18next.t("checkoutPage.outside_delivery_area")}
                  </p>
                  {this.state.phone_netWork == false ? (
                    <button
                      id="roundb"
                      type="button"
                      onClick={this.addContact}
                      disabled={this.state.contactNumber !== "" ? false : true}
                      className="btn btn-sm custom-default-red-button"
                    >
                      {i18next.t("checkoutPage.continue")}
                    </button>
                  ) : (
                    <button
                      disabled={this.state.phone_netWork}
                      id="roundb"
                      className="btn btn-sm custom-default-red-button"
                    >
                      <i className="fa fa-spinner fa-spin"></i>{" "}
                      {i18next.t("checkoutPage.loading")}
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="checkout-steps-action">
              {this.state.netWork == false ? (
                <button
                  {...(this.checkPhone()
                    ? { onClick: this.saveStoreData }
                    : { "data-toggle": "modal" })}
                  {...(this.checkPhone() == false && {
                    "data-target": `#activeInactive`,
                  })}
                  id="roundb"
                  className="btn custom-default-red-button float-right"
                  disabled={!this.islocationReady()}
                >
                  {i18next.t("checkoutPage.continue")}
                </button>
              ) : (
                <button
                  disabled={this.state.netWork}
                  id="roundb"
                  className="btn custom-default-red-button float-right"
                >
                  <i className="fa fa-spinner fa-spin"></i>
                  {i18next.t("checkoutPage.loading")}
                </button>
              )}
            </div>
          </div>
        </div>
        <BsModal show={this.state.showHide} onHide={this.handleClose}>
          <BsModal.Header closeButton>
            <BsModal.Title>
              {i18next.t("checkoutPage.items_not_available")}
            </BsModal.Title>
          </BsModal.Header>
          <BsModal.Body>
            <table className="table">
              {this.state.removeableLsmItemsArr &&
                this.state.removeableLsmItemsArr.map((item: any) => (
                  <tbody>
                    <tr className="product-row">
                      {/* <td>{fav.combo_id ? fav.combo_id : fav.menu_item_id}</td> */}
                      <td className="product-col">
                        <figure className="product-image-container">
                          <a className="product-image">
                            <img
                              src={`${item.selectedsize
                                ? item.selectedsize.image_url
                                : item.combo.image_url
                                }`}
                              alt="product"
                              style={{ width: "8rem" }}
                            />
                          </a>
                        </figure>
                      </td>
                      <td>
                        {item.item && item.item.item_name}
                        {item.combo && item.combo.combo_name}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </BsModal.Body>
          <BsModal.Footer>
            <Button
              className="btn custom-default-red-button"
              onClick={this.removeLsmItems}
            >
              {i18next.t("checkoutPage.confirm")}
            </Button>
          </BsModal.Footer>
        </BsModal>

        <div
          id={"activeInactive"}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  {i18next.t("errorMsg.invalid_phone")}
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{i18next.t("checkoutPage.invalid_phone_text")}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  {i18next.t("common.close")}
                </button>
                <button
                  onClick={this.GoToProfile}
                  data-dismiss="modal"
                  className="btn btn-primary"
                >
                  {i18next.t("common.ok")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  calcItemTotal = (data: any) => {
    let sum = 0;
    data.forEach((item: any) => {
      sum += parseFloat(item.totalItemPrice);
    });
    this.itemTotal = sum;
    return sum;
  };
  calculateOrderDiscount = (data: any) => {
    let discount = 0;
    data.forEach((item: any) => {
      if (item.combo) {
        if (item.combo.discount_price) {
          discount += item.quantity * item.combo.combo_mrp_price - item.combo.discount_price;
        }
      } else {
        if (item.selectedsize.discount_price) {
          discount += item.quantity * (item.selectedsize.mrp - item.selectedsize.discount_price);
        }
      }
    });
    return discount;
  };
  calcTotalTax = (taxAmount: any) => {
    let totalTax = 0;
    if (this.props.paymentMethod == 'Cash') {
      if (this.props.taxDataCash.tax_type == 0) {
        let tax_rate = this.props.taxDataCash.tax_percent;
        totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
        this.totalTax = totalTax.toFixed(2);
      }
      else {
        totalTax = this.cartTotal * this.props.taxDataCash.tax_percent / 100;
        this.totalTax = totalTax.toFixed(2);
        console.log("total tax in case of exclusive tax", totalTax)

      }
    }
    else if (this.props.paymentMethod == 'Card') {
      if (this.props.taxDataCard.tax_type == 0) {
        let tax_rate = this.props.taxDataCard.tax_percent;
        totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
        this.totalTax = totalTax.toFixed(2);
      }
      else {
        totalTax = this.cartTotal * this.props.taxDataCard.tax_percent / 100;
        this.totalTax = totalTax.toFixed(2);
      }
    }
    else if (this.props.paymentMethod == 'COD' || this.props.paymentMethod === 'COP') {
      if (this.props.taxDataCard.tax_type == 0) {
        let tax_rate = this.props.taxDataCard.tax_percent;
        totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
        this.totalTax = totalTax.toFixed(2);
      }
      else {
        totalTax = this.cartTotal * this.props.taxDataCard.tax_percent / 100;
        this.totalTax = totalTax.toFixed(2);
      }
    }
    this.props.SaveTaxValue(this.totalTax)
    return totalTax;
  }


  calcTotal = (data: any) => {
    let { deliveryfee, coupon } = this.props;
    this.couponDiscount = 0;
    this.discount = 0;
    let sum: any = 0;
    // let Coupon: string = coupon && coupon.channel;
    data.forEach((item: any) => {
      sum += parseFloat(item.totalItemPrice);
    });
    let discountValue: any = 0;
    if (!_.isEmpty(coupon)) {
      // if (
      //   Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
      //   this.props.orderType
      // ) {
      if (sum > coupon.min_amount) {
        if (coupon.free_delivery === 0) {
          if (coupon.type == "menu") {
            if (coupon.coupon_value !== 0) {
              this.couponDiscount = coupon.coupon_value;
              discountValue += coupon.coupon_value;
            } else {
              discountValue = (sum * coupon.percent) / 100;
              this.couponDiscount = discountValue;
            }
            if (this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items")
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          } else if (coupon.type == "group") {
            let CouponGroupsArr = JSON.parse(coupon.groups_json);
            data.forEach((item: any, index: any) => {
              CouponGroupsArr.map((coupon_groups: any) => {
                if (item.combo) {
                  if (item.combo.group_id.group_id == coupon_groups.value) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value;
                      this.couponDiscount = discountValue;
                      // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
                if (item.item) {
                  console.log("group coupon array here", CouponGroupsArr)
                  if (
                    item.item.item_group_id.group_id == coupon_groups.value ||
                    item.item.group_id == coupon_groups.value
                  ) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value;
                      this.couponDiscount = discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
              });
            });
        if(this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items")
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          } else if (coupon.type == "item") {
            let CouponitemsArr = JSON.parse(coupon.items_json);
            data.forEach((item: any, index: any) => {
              CouponitemsArr.map((coupon_items: any) => {
                if (item.combo && coupon_items.combo_id) {
                  if (item.combo.combo_id == coupon_items.combo_id) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value * item.quantity;
                      this.couponDiscount += discountValue;
                  // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                  // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
            }
            if (item.item && coupon_items.menu_item_id) {
              if (item.item.menu_item_id == coupon_items.menu_item_id) {
                if (coupon.coupon_value !== 0) {
                  discountValue = coupon.coupon_value * item.quantity;
                  this.couponDiscount += discountValue;
                  // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                  // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
            }
          });
        });
        if(this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items")
          this.props.resetCoupon({});
          this.codeExeCount = 1
        }
      } else if (coupon.type == "store") {
        if (coupon.coupon_value !== 0) {
          this.couponDiscount = coupon.coupon_value;
          discountValue += coupon.coupon_value;
        } else {
          discountValue = (sum * coupon.percent) / 100;
          this.couponDiscount += discountValue;
        }
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items")
          this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          }
        } else {
          this.props.saveDeliveryFee(0)
        }
      }
      else {
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on cart value below " + coupon.min_amount)
          this.props.resetCoupon({});
          this.codeExeCount = 1
        }
      }
    } else {
      this.discount = 0;
      this.couponDiscount = 0;
      this.cartTotal = 0;
    }
    if(this.couponDiscount > sum) {
        this.couponDiscount = sum;
        sum = 0;
    }
    if (this.props.orderType == "Delivery") {
      sum = this.couponDiscount > sum ? 0 + parseFloat(deliveryfee) :  sum - this.couponDiscount + parseFloat(deliveryfee);
      // sum = sum - this.couponDiscount + parseFloat(deliveryfee);
    } else if (this.props.orderType == "Pickup") {
      console.log("cart total for pickup case",this.cartTotal)
      sum = this.couponDiscount > sum ? 0 : sum - this.couponDiscount;
      // sum = sum - this.couponDiscount;
    }
    this.props.SaveDiscountValue(this.couponDiscount);
    this.cartTotal = sum;
    if (this.props.paymentMethod == "Cash") {
      if (this.props.taxDataCash.tax_type == 1) {
        sum = parseFloat(sum) + parseFloat(this.totalTax);
        // sum = parseInt(sum);
      } else {
        sum = parseFloat(sum);
      }
    } else if (this.props.paymentMethod == "Card") {
      if (this.props.taxDataCard.tax_type == 1) {
        sum = parseFloat(sum) + parseFloat(this.totalTax);
        // sum = parseInt(sum);
      } else {
        sum = parseFloat(sum);
      }
    }
    return sum;
  };

  calcTotalDiscount = (discount: any) => {
    return discount;
  };

  productItem = (data: any) => {
    let { deliveryfee, coupon } = this.props;
    let { cartTotal } = this.state;
    cartTotal = this.cartTotal;
    // let Coupon: string = coupon && coupon.channel;
    if (!_.isEmpty(data)) {
      let cartData: any[] = JSON.parse(data);
      if (cartData.length > 0) {
        return (
          <div className="col-lg-4">
            <div className="cart-summary">
              <h3> {i18next.t("checkoutPage.order_summary")}</h3>
              {/* <h4>
                <a
                  className="custom-default-fonts-color"
                >
                  {cartData.length} {i18next.t("checkoutPage.products_in_cart")}
                </a>
              </h4> */}
              {/* <Scrollbars style={{ height: 250 }}>
                <div id="total-estimate-section">
                  <table className="table table-mini-cart">
                    <tbody>
                      {cartData.map((carts, indexes) => (
                        <div style={{ display: "contents" }}>
                          <tr className="d-flex" key={indexes}>
                            <td className="product-col col-3">
                              <figure className="product-image-container">
                                <a className="product-image">
                                  <img
                                    src={`${carts.selectedsize
                                      ? carts.selectedsize.image_url
                                      : carts.image
                                      }`}
                                    alt="product"
                                  />
                                </a>
                              </figure>
                            </td>
                            <td className="col-6">
                              <div>
                                <h2 className="product-title custom-default-fonts-color">
                                  {!_.isEmpty(carts.item) && (
                                    <a> 
                                      {carts.quantity}
                                      {"X. "}
                                      {carts.item.item_name}                                      
                                    </a>
                                  )}
                                  {!_.isEmpty(carts.combo) && (
                                    <a>                                      
                                      {carts.quantity}
                                      {"X. "}
                                      {carts.combo.combo_name} 
                                    </a>
                                  )}
                                </h2>
                              </div>
                            </td>
                            {carts.selectedsize ? (
                              <td className="col-md-3">
                                {(carts.selectedsize.discount_price || carts.selectedsize.discount_price == 0) &&
                                  carts.selectedsize.order_channel == "online" ? (
                                  <>
                                    {priceunit +
                                      (carts.selectedsize.discount_price * carts.quantity).toFixed(2)}
                                  </>
                                ) : (
                                  <>
                                    {priceunit +
                                      (carts.selectedsize.mrp * carts.quantity).toFixed(2)}
                                  </>
                                )}
                              </td>
                            ) : (
                              <td className="col-md-3">
                                {priceunit + (carts.price * carts.quantity).toFixed(2)}
                              </td>
                            )}
                          </tr>
                          <tr>
                            {!_.isEmpty(carts.item) &&
                              !_.isEmpty(carts.item.modifiers) &&
                              Object.keys(carts.item.modifiers).map(
                                (key: any, index) => {
                                  return carts.item.modifiers[key].map(
                                    (item: any, index: any) => {
                                      return (
                                        item.selected === true && (
                                          <tr className="d-flex">
                                            <td className="col-3"></td>
                                            <td className="col-6">
                                              {(item.quantity * carts.quantity) + "X. " + item.modifier_name}
                                            </td>
                                            <td className="col-3">
                                              {priceunit +
                                                (item.modifier_sale_price *
                                                  (item.quantity * carts.quantity)).toFixed(2)}
                                              {""}
                                            </td>
                                          </tr>
                                        )
                                      );
                                    }
                                  );
                                }
                              )}
                            {!_.isEmpty(carts.combo) &&
                              !_.isEmpty(carts.combo.modifiers) &&
                              Object.keys(carts.combo.modifiers).map(
                                (key: any, index) => {
                                  return carts.combo.modifiers[key].map(
                                    (item: any, index: any) => {
                                      return (
                                        item.selected === true && (
                                          <tr className="d-flex">
                                            <td className="col-3"></td>
                                            <td className="col-6">
                                              {(item.quantity * carts.quantity) + " x " + item.modifier_name}
                                            </td>
                                            <td className="col-3">
                                              {priceunit +
                                                item.modifier_sale_price *
                                                item.quantity * carts.quantity}
                                              {""}
                                            </td>
                                          </tr>
                                        )
                                      );
                                    }
                                  );
                                }
                              )}

                            {!_.isEmpty(carts.combo) &&
                              !_.isEmpty(carts.combo.choices) &&
                              Object.keys(carts.combo.choices).map(
                                (key: any, index) => {
                                  return carts.combo.choices[key].map(
                                    (item: any, index: any) => {
                                      return (
                                        item.selected === true &&
                                        item.quantity > 0 && (
                                          <tr className="d-flex">
                                            <td className="col-3"></td>
                                            <td className="col-6">
                                              {item.size ? (
                                                <span>
                                                  {item.item_name}(
                                                  {JSON.parse(item.size).size})
                                                  x{" "}
                                                  {item.quantity *
                                                    carts.quantity}
                                                </span>
                                              ) : (
                                                <span>
                                                  {item.item_name +
                                                    " x " +
                                                    item.quantity}
                                                </span>
                                              )}
                                            </td>
                                            {item.extra_price > 0 &&
                                              item.quantity == 1 ? (
                                              <td className="col-3">
                                                {priceunit +
                                                  item.extra_price *
                                                  carts.quantity}
                                                {""}
                                              </td>
                                            ) : (
                                              <td className="col-3">
                                                {priceunit +
                                                  item.extra_price *
                                                  item.quantity *
                                                  carts.quantity}
                                                {""}
                                              </td>
                                            )}
                                          </tr>
                                        )
                                      );
                                    }
                                  );
                                }
                              )}
                          </tr>
                          <tr>
                            <tr className="d-flex">
                              <td className="col-3"></td>
                              <td className="col-6">
                                {i18next.t("checkoutPage.total")}
                              </td>
                              <td className="col-3">
                                {priceunit + (carts.totalItemPrice).toFixed(2)}
                                {""}
                              </td>
                            </tr>
                          </tr>
                        </div>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Scrollbars> */}
              {/* cart items end */}
              {this.props.orderType === "Delivery" && (
                <div className="checkout-info-box">
                  <address>
                    <h5> {i18next.t("checkoutPage.delivery_address")}</h5>
                    <p>
                      {(this.props.detail_address !== "" && this.state.description == "" && this.state.buildingname == "" && this.state.room == "" && this.state.area_details == '') ? this.props.detail_address + " /" + this.props.address_save :
                        this.state.description +
                        ((this.state.description != "" && this.state.buildingname != "") ? ", " : "") +
                        this.state.buildingname +
                        (this.state.room != "" ? ", " : "") +
                        // this.state.buildingnumber +
                        // " , " +
                        this.state.room + (this.state.area_details !='' ? ", " : "") + 
                        this.state.area_details +
                        ((this.state.description != "" || this.state.buildingname != "" || this.state.room != "") ? " /" : "") +
                        this.props.address_save}
                    </p>
                  </address>
                  {this.props.houseDetail && this.state.correctaddress == true && (
                    <address>
                      <h5> {i18next.t("checkoutPage.detail_for_address")}</h5>
                      <p>{this.props.houseDetail}</p>
                    </address>
                  )}
                </div>
              )}
              {/* address end */}

              {coupon && this.couponDiscount == 0 && (
                <div>
                  <h4 style={{ paddingTop: "10px" }}>
                    {i18next.t("checkoutPage.apply_discount_code")}
                  </h4>
                  <div
                    // action="#"
                    style={{ paddingTop: "10px", marginBottom: "-1px" }}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={i18next.t(
                          "checkoutPage.apply_discount_code"
                        )}
                        name="coupanCode"
                        onChange={this.handleChangeCoupan}
                      />
                      <div className="input-group-append">
                        <button
                          id="roundb"
                          className="btn btn-sm btn-primary custom-default-red-button"
                          disabled={!this.isCoupanReady()}
                          onClick={() => this.ApplyCoupan()}
                        // type="submit"
                        >
                          {i18next.t("checkoutPage.apply_coupon_btn")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* coupan end */}
              <table className="table table-totals">
                <tbody>
                  <tr>
                    <td>{i18next.t("checkoutPage.subtotal")}</td>
                    <td>{priceunit + this.calcItemTotal(cartData).toFixed(2)}</td>
                  </tr>

                  {this.props.paymentMethod == "Cash" ? (
                    <React.Fragment>
                      {/* <tr>
                        <td>
                          {i18next.t("checkoutPage.vat")}:{" "}
                          {this.props.taxDataCash.tax_percent}%
                        </td>
                        <td>
                          {priceunit + this.calcTotalTax(this.props.taxAmount).toFixed(2)}
                        </td>
                      </tr> */}
                      {this.props.taxDataCash.tax_type == 1 ?
                        <tr>
                          <td>{i18next.t('checkoutPage.vat')}:  {this.props.taxDataCash.tax_percent}%</td>
                          <td>{priceunit + this.calcTotalTax(this.props.taxAmount).toFixed(2)}</td>
                        </tr>
                        :
                        <tr>
                          <td>{i18next.t('checkoutPage.vat_incl')}{this.props.taxDataCash.tax_percent}%</td>
                          <td>{priceunit + this.calcTotalTax(this.props.taxAmount).toFixed(2)}</td>
                        </tr>
                      }
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {/* <tr>
                        <td>
                          {i18next.t("checkoutPage.vat")}:{" "}
                          {this.props.taxDataCard.tax_percent}%
                        </td>
                        <td>
                          {priceunit + this.calcTotalTax(this.props.taxAmount)}
                        </td>
                      </tr> */}
                      {this.props.taxDataCard.tax_type == 1 ?
                        <tr>
                          <td>{i18next.t('checkoutPage.vat')}:  {this.props.taxDataCard.tax_percent}%</td>
                          <td>{priceunit + this.calcTotalTax(this.totalTax).toFixed(2)}</td>
                        </tr>
                        :
                        <tr>
                          <td>{i18next.t('checkoutPage.vat_incl')}{this.props.taxDataCard.tax_percent}%</td>
                          <td>{priceunit + this.calcTotalTax(this.props.taxAmount).toFixed(2)}</td>
                        </tr>
                      }
                    </React.Fragment>
                  )}
                  {this.props.orderType == "Delivery" &&
                    !_.isEmpty(this.props.storeSelected) && (
                      <tr>
                        {this.props.deliveryfee == 0 ?
                          <>
                            <td className="discountStyle">{i18next.t("checkoutPage.delivery_fee")}</td>
                            <td className="discountStyle">{priceunit + deliveryfee}</td>
                          </>
                          :
                          <>
                            <td>{i18next.t("checkoutPage.delivery_fee")}</td>
                            <td>{priceunit + deliveryfee}</td>
                          </>
                        }
                      </tr>
                    )}
                  {
                    !_.isEmpty(coupon) && this.props.discountAmount > 0 && (
                      // Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
                      // this.props.orderType && (
                      <tr>
                        <td className="discountStyle">
                          {i18next.t("checkoutPage.discount")}
                        </td>
                        <td className="discountStyle">
                          {coupon.coupon_value !== 0 
                            ? `${priceunit +
                            this.calcTotalDiscount(this.props.discountAmount).toFixed(2)
                            }`
                            : `${priceunit +
                            this.calcTotalDiscount(this.props.discountAmount).toFixed(2)
                            }`}
                        </td>
                      </tr>
                    )
                    // )
                  }
                  {this.calculateOrderDiscount(cartData) > 0 && (
                    <tr>
                      <td className="discountStyle">
                        {i18next.t("common.your_discount")}
                      </td>
                      <td className="discountStyle">
                        {priceunit + this.calculateOrderDiscount(cartData).toFixed(2)}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td>{i18next.t("checkoutPage.order_total")}</td>
                    <td>{priceunit + this.calcTotal(cartData).toFixed(2)}</td>
                  </tr>
                </tfoot>
              </table>

              {/* <div className="checkout-methods"> */}
              {/* <Link
                  style={{
                    backgroundColor: "#4b2852",
                    borderColor: "#4b2852",
                  }}
                  to="/payments"
                  className="btn btn-block btn-sm btn-primary"
                >
                  Go to Checkout
                </Link> */}
              {/* <a href="#" className="btn btn-link btn-block">Check Out with Multiple Addresses</a> */}
              {/* </div> */}
            </div>
          </div>
        );
      } else {
        <div>{i18next.t("common.empty_cart")}</div>;
      }
    } else {
      <div>{i18next.t("common.empty_cart")}</div>;
    }
  };
  setDeliveryAddress(data: any) {
    console.log('data saved location',data);
    
    this.setState({ selectedAddress: data.address_id, changeLocation: false })
    this.props.saveGuestAddress(data.full_address);
    this.props.saveAddressExtraDetails(data.extra_details)
    Geocode.fromAddress(data.full_address)
      .then((json: any) => {
        var location = json.results[0].geometry.location;
        this.setState({ lat: location.lat, lng: location.lng });
        this.props.findLocalStores(location.lat, location.lng);
      })
      .catch((error: any) => {
        // console.warn(error));
      });
  }
  manageAddressFun() {
    let { addressData, orderType } = this.props;
    return (
      <div className="row">
        {addressData && addressData.length > 0 && orderType == "Delivery" && (
          <div className="col-12">
            <h3>{i18next.t("checkoutPage.saved_location")}</h3>
          </div>
        )}
        {addressData &&
          addressData.length > 0 &&
          orderType == "Delivery" &&
          addressData.map((obj: any, index: any) => {
            return (
              <div className="col-12">
                <div
                  className="storeShow-cart"
                  onClick={() => this.setDeliveryAddress(obj)}
                >
                  <input
                    type="radio"
                    id="pick"
                    name="store"
                    value="address"
                    {...(this.state.selectedAddress == obj.address_id && {
                      checked: true,
                    })}
                    style={{ marginRight: "15px" }}
                  />
                  <p style={{ fontSize: "15px", color: "black", marginRight: "8px" }}>
                    <strong>{obj.place=="Home"?i18next.t("manageAddress.home"):obj.place=="Office"?i18next.t("manageAddress.office"):obj.place=="Other"?i18next.t("manageAddress.other"):null}</strong>
                    {obj.extra_details && 
                    <>
                    <br/>
                    {obj.extra_details }
                    </>
                    }
                    {obj.full_address &&
                    <>
                    <br/>
                    {obj.full_address}
                    </>
                    }
                  </p>
                </div>
              </div>
            );
          })}
          {this.props.addressData.length > 0 && 
          <div className="col-12">
                <div
                  className="storeShow-cart"
                  onClick={() => {this.setState({changeLocation: !this.state.changeLocation})}}
                >
                  <input
                    type="radio"
                    id="changeLoc"
                    name="changelocation"
                    value="changelocation"
                    {...(this.state.changeLocation  && {
                      checked: true,
                    })}
                    // {...(this.state.selectedAddress == obj.address_id && {
                    //   checked: true,
                    // })}
                    style={{ marginRight: "15px" }}
                  />
                  <p style={{ fontSize: "15px", color: "black",marginRight: "8px" }}>
                    <strong>Change your location</strong> <br />
                  </p>
                </div>
              </div>
              }
      </div>
    );
  }
  locationTabs = () => {
    let { cart } = this.props;
    const token = localStorage.getItem("token");
    return (
      <div className="row">
        <div className="locationsec col-lg-8">
          <h4 className="locationtitle">{i18next.t('checkoutPage.select_location')}</h4>
          {/* {!token ? (
                        <div className="loginCart">
                            <p>{i18next.t('checkoutPage.pick_location_text')}</p>
                            <Link to="/login" className="loginCartButton custom-default-background-color">
                                {i18next.t('common.login')}
                            </Link>
                        </div>
                    ) : null} */}
          {/* {!token ? <h5 className="orContinue">{i18next.t('checkoutPage.continue_as_guest')}</h5> : null} */}

          {/* delivery and pickup radio buttons options */}
          {/* <div className="cart-table-container">
                        <h4> {i18next.t('checkoutPage.order_method_description')}</h4>
                        <div className="iwant-sec">
                            <ul className="iwnt-toggleButton seconds">
                                <li onChange={() => this.props.saveOrderType("Delivery")}>
                                    <input
                                        type="radio"
                                        id="delivery"
                                        name="drone"
                                        value="Delivery"
                                        {...(this.props.orderType == "Delivery" && {
                                            checked: true
                                        })}
                                        className="order_method_radio"
                                    />
                                    <label
                                        htmlFor="delivery"
                                        className="my-1"
                                        style={{ fontSize: "15px" }}
                                    >
                                        {i18next.t('checkoutPage.order_method_delivery')}{" "}
                                    </label>
                                </li>
                                <li onChange={() => this.props.saveOrderType("Pickup")}>
                                    <input
                                        type="radio"
                                        id="pick"
                                        name="drone"
                                        {...(this.props.orderType == "Pickup" && {
                                            checked: true
                                        })}
                                        className="order_method_radio"
                                    />
                                    <label
                                        htmlFor="pick"
                                        className="my-1"
                                        style={{ fontSize: "15px" }}
                                    >
                                        {i18next.t('checkoutPage.order_method_pickup')}
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div> */}

          {this.props.orderType === "Delivery"
            ? this.DeliveryFunction()
            : this.props.orderType === "Pickup"
              ? this.PickupFunction()
              : null}
        </div>
        {/* {this.productItem(cart)} */}
      </div>
    );
  };
  render() {
    const token = localStorage.getItem("token");
    return (
      <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div className="container">
            <div className="backBtn-wrapper">
              <Link to={`/home${window.location.search}`}>
                <div style={{marginBottom: '20px'}} className="org-header__btn--circle">
                  <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg>
                </div>
              </Link>
            </div>
          </div>
        </nav>

        <div className="container">
          <ul className="checkout-progress-bar">
            <li
              id="responsiveTabs"
              {...(this.props.tabName == "location" && {
                className: "active",
              })}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.history.push("/auth");
                this.props.setTabName("auth");
              }}
            >
              <span>{i18next.t("checkoutPage.account")}</span>
            </li>
            <Link to={`/location${window.location.search}`}>
              <li
                id="responsiveTabs"
                {...(this.props.tabName == "location" && {
                  className: "active",
                })}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.props.setTabName("location");
                }}
              >
                <span>{i18next.t("checkoutPage.set_location")}</span>
              </li>
            </Link>
            {/* {!token ? (
                            <li
                                id="responsiveTabs"
                                {...((this.props.tabName == "details" ||
                                    this.props.tabName == "payment") && {
                                    className: "active",
                                })}
                                style={{ cursor: "pointer" }}
                                {...(this.islocationReady() && {
                                    onClick: () => {
                                        this.props.history.push("/guestDetails")
                                        this.props.setTabName("details")
                                    }
                                })}
                            >
                                <span>{i18next.t('checkoutPage.details')}</span>
                            </li>
                        ) : null} */}
            <li id="responsiveTabs" style={{ cursor: "pointer" }}>
              <span>{i18next.t("checkoutPage.payment")}</span>
            </li>
          </ul>
          {this.props.tabName == "location" && this.locationTabs()}
        </div>

        <div className="mb-6"></div>
      </main>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    stores: state.login.storesList,
    cart: state.login.cartData,
    orderType: state.login.orderType,
    paymentMethod: state.login.paymentMethod,
    deliveryfee: state.login.deliveryfee,
    houseDetail: state.login.houseDetail,
    storeSelected: state.login.storeSelected,
    selectStoreId: state.login.selectStoreId,
    addressType: state.login.addressType,
    address_save: state.login.address_save,
    taxData: state.login.taxData,
    addressData: state.login.addressData,
    coupanCode: state.login.coupanCode,
    coupon: state.login.coupon,
    tabName: state.login.tabName,
    taxDataCash: state.login.taxDataCash,
    taxDataCard: state.login.taxDataCard,
    taxAmount: state.login.taxAmount,
    discountAmount: state.login.discountAmount,
    specificStoreItems: state.login.specificStoreItems,
    menuStoreId: state.login.menuStoreId,
    detail_address: state.login.detail_address,
    pickupStoresList: state.login.pickupStoresList,
    extra_address_details:state.login.extra_address_details
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setTabName: (tabName: any) => {
      dispatch(setTabName(tabName));
    },
    findLocalStores: function (lat: any, long: any) {
      dispatch(findLocalStores(lat, long));
    },
    findPickupStores: function (lat: any, long: any, value: any) {
      dispatch(findPickupStores(lat, long, value));
    },
    saveStore: (store: any) => {
      dispatch(saveStore(store));
    },
    saveSelectStoreId: (store: any) => {
      dispatch(saveSelectStoreId(store));
    },
    saveDeliveryFee: (deliveryfee: any) => {
      dispatch(saveDeliveryFee(deliveryfee));
    },
    saveGuestAddress: function (address: any) {
      dispatch(saveGuestAddress(address));
    },
    saveOrderType: function (type: any) {
      dispatch(saveOrderType(type));
    },
    saveHouseDetail: (address: any) => {
      dispatch(saveHouseDetail(address));
    },
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    addressesListk: function () {
      dispatch(addressesListk());
    },
    applyCoupon: function (coupon: any) {
      dispatch(applyCoupon(coupon));
    },
    addContact: function (obj: any) {
      dispatch(addContact(obj));
    },
    addAddress: (data: any) => {
      dispatch(addAddressUser(data));
    },
    saveurlMethod: function (url: any) {
      dispatch(saveurlMethod(url));
    },
    SaveTaxValue: function (taxAmount: any) {
      dispatch(SaveTaxValue(taxAmount));
    },
    SaveDiscountValue: function (discountAmount: any) {
      dispatch(SaveDiscountValue(discountAmount));
    },
    saveAddress: function (detailAddress: any) {
      dispatch(saveAddress(detailAddress))
    },
    resetCoupon: function (obj:any) {
      dispatch(resetCoupon(obj))
    },
    saveAddressExtraDetails: (details:any) => {
      dispatch(saveAddressExtraDetails(details))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);