import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AccountAuthState, LoyalityAuthProps } from "../Interface/loginInterface";
import "./styleCheckout.css";
import { setTabName } from "../../Redux";
import i18next from "i18next";
import { connect } from "react-redux";
import _ from "lodash";
import { changeAuthScreen, CreateAccount, phoneValidationByOTP, saveurlMethod, ValidateOtpForValidation } from "../../Redux/Actions";
import "react-responsive-modal/styles.css";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { ReactTimer } from '../timer/timer'
import '../Login/stylelogin.css'
import '../Login/invoice.css'

class index extends Component<LoyalityAuthProps, AccountAuthState> {
    currentTime: any;
    validTimeFlag: any;
    WindowObj: any;
    timer: any;
    constructor(props: any) {
        super(props);

        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            isValidEmail: true,
            phonenumber: "971",
            laterdatetime: new Date(),
            saveregister: false,
            coupanCode: "",
            discountFlag: false,
            validTimeFlag: '',
            netWork: false,
            cartTotal: 0,
            otp: "",
            receivedCode: true
        };

        this.handleChangeGuest = this.handleChangeGuest.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTabName("loyalityAuth")
        this.props.changeAuthScreen();
    }
    handleInputChange(event: { target: { name: any; value: any } }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    isPhoneReady = () => {
        let {
            phonenumber
        } = this.state;
        return (
            (phonenumber.length > 10 || phonenumber.length < 15) &&
            this.checkPhone(phonenumber)
        );
    };
    isOTPReady = () => {
        let { otp } = this.state;
        return (otp !== '');
    }
    checkPhone = (phone: any) => {
        if (phone) {
            var phoneno2 = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{10}$/;  // uae mobile numbers
            var phoneno = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{7}$/;  // uae mobile numbers
            var phoneno3 = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{8}$/;  // uae mobile numbers
            var phoneno4 = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56|57|58|59)[0-9]{9}$/;  // uae mobile numbers
            if (phone.match(phoneno) || phone.match(phoneno2) || phone.match(phoneno3) || phone.match(phoneno4)) {
                return true;
            } else {
                return false;
            }
        }
    };

    handleChangeGuest(event: { target: { name: any; value: any } }) {
        if (event.target.name == 'phonenumber') {
            let maxNum = 11;
            if (event.target.value.length > maxNum) {
                event.target.value = event.target.value.slice(0, maxNum);
            }
        }
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleOnChange = (value: any, data: any) => {
        this.setState({ phonenumber: value });
    };
    validateEmail = (event: { target: { name: any; value: any } }) => {
        // let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
        let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (event.target.value != '') {
            if (reg.test(event.target.value)) {
                this.setState({ email: event.target.value, isValidEmail: true });
            } else {
                this.setState({ email: event.target.value, isValidEmail: false })
            }
        }
        else {
            this.setState({ email: event.target.value, isValidEmail: true })
        }
    };
    handleSubmit = () => {
        let { phonenumber } = this.state;
        this.setState({ receivedCode: true })
        let str_phone_number = phonenumber;
        
        this.props.phoneValidationByOTP(str_phone_number);
        setTimeout(() => {
            this.setState({ receivedCode: false })
        }, 30000)
    };
    createAccount = () => {
        let { firstname, lastname, email } = this.state;
        let str_phone_number = localStorage.getItem("phoneNumber");
        let obj: any = {
            first_name: firstname,
            last_name: lastname,
            login_name: firstname + " " + lastname,
            phone_number: str_phone_number,
            email_address: email,
            is_active: 1,
            loyality: true
        };
        this.props.createAccount(obj, this.props.history);
    };
    isUserReady = () => {
        let {
            firstname,
            lastname,
            email,
            isValidEmail,
        } = this.state;

        return (
            firstname !== "" &&
            lastname !== "" &&
            email !== "" &&
            isValidEmail == true
        );
    };
    handleSubmitOTP = () => {
        const { otp } = this.state;
        let str_phone_number = localStorage.getItem("phoneNumber")
        let data = {
            phone: str_phone_number,
            otp: otp,
            loyality: true
        }
        this.props.saveurlMethod('loyaltyAuth');
        this.props.ValidateOtpForValidation(data, this.props.history)
    }
    accountAuth = () => {
        return (
            <div className="row">
                {
                    this.props.accountAuthStatus == "1"
                        ? this.phoneField()
                        : this.props.accountAuthStatus == "2"
                            ? this.OtpValidation()
                            : (this.props.accountAuthStatus == "3")
                                ? this.userRegister()
                                : null
                }
            </div>
        );
    };
    phoneField = () => {
        return (
            <div className="locationsec col-lg-8 order-lg-first">
                <div className="checkout-payment">
                    <h2 className="text-center">{i18next.t('checkoutPage.enter_number')}</h2>
                    <p className="text-center">{i18next.t('checkoutPage.phone_validation_text')}</p>
                    <div id="new-checkout-address" className="show">
                        <div className="col-md-12 required-field">
                            <label>{i18next.t('checkoutPage.phone_number')} </label>
                            <p>Enter your phone number with (5xx-xxx-xxx)</p>
                            <PhoneInput
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true
                                }}
                                country={'ae'}
                                disableDropdown={true}
                                onlyCountries={['ae']}
                                countryCodeEditable={false}
                                value={this.state.phonenumber}
                                onChange={this.handleOnChange}
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button id="roundb" disabled={!this.isPhoneReady()} onClick={this.handleSubmit} type="button" className="btn cart-button float-right mt-2">{i18next.t('checkoutPage.sendCode')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    backAuthScreen = () => {
        this.props.changeAuthScreen();
    }
    OtpValidation = () => {
        return (
            <div className="locationsec col-lg-8 order-lg-first">
                <div className="checkout-payment">
                    <h2 className="text-center">{i18next.t('forgotPass.enter_otp')}</h2>
                    <p>{i18next.t('forgotPass.enter_otp_text')}</p>
                    <div id="new-checkout-address" className="show">
                        <input id="round" className="form-control" style={{ fontSize: "15px" }} size={40} type="tel" placeholder={i18next.t('forgotPass.enter_otp')} maxLength={11} name="otp" onChange={this.handleInputChange} required />
                        <span> <a style={{ cursor: 'pointer' }} className="colorLink" {...(this.state.receivedCode == false && { onClick: this.backAuthScreen })}> I have not received code </a> {this.state.receivedCode == true && <ReactTimer code={this.state.receivedCode} />} </span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button id='roundb' disabled={!this.isOTPReady()} onClick={this.handleSubmitOTP} type="button" className="btn cart-button float-right mt-2">{i18next.t('forgotPass.proceed')}</button>
                    </div>
                </div>
            </div>
        )
    }
    userRegister = () => {
        return (
            <div className="locationsec col-lg-8 order-lg-first">
                <div className="checkout-payment">
                    <h2 className="step-title">{i18next.t('checkoutPage.basic_detail')}</h2>
                    <div id="new-checkout-address" className="show">
                        <form action="#" className="row">
                            <div className="required-field col-md-6">
                                <label>{i18next.t('checkoutPage.first_name')} </label>
                                <input
                                    id="round"
                                    style={{ fontSize: "16px" }}
                                    type="text"
                                    className="form-control"
                                    placeholder={i18next.t('checkoutPage.first_name')}
                                    name="firstname"
                                    autoFocus={true}
                                    onChange={this.handleChangeGuest}
                                    required
                                />
                                {this.state.firstname === "" ? (
                                    <p style={{ color: "red" }}>{i18next.t('common.required')}</p>
                                ) : null}
                            </div>
                            <div className="required-field col-md-6">
                                <label>{i18next.t('checkoutPage.last_name')}  </label>
                                <input
                                    id="round"
                                    style={{ fontSize: "16px" }}
                                    type="text"
                                    className="form-control"
                                    placeholder={i18next.t('checkoutPage.last_name')}
                                    name="lastname"
                                    onChange={this.handleChangeGuest}
                                    required
                                />
                                {this.state.lastname === "" ? (
                                    <p style={{ color: "red" }}>{i18next.t('common.required')} </p>
                                ) : null}
                            </div>
                            <div className="required-field col-md-12">
                                <label>{i18next.t('checkoutPage.email_address')}  </label>
                                <input
                                    id="round"
                                    style={{ fontSize: "16px" }}
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    autoComplete="off"
                                    onChange={email => this.validateEmail(email)}
                                    required />

                                {this.state.isValidEmail == false &&
                                    this.state.email !== "" && (
                                        <p style={{ color: "red" }}>{i18next.t('errorMsg.invalid_email')}</p>
                                    )}
                            </div>
                        </form>
                    </div>
                    <div className="clearfix">
                        {this.props.successStatus === "successregister" ? (
                            <div className="alert alert-success alert-dismissible">
                                <button type="button" className="close" data-dismiss="alert">
                                    &times;
                                </button>
                                <strong>{i18next.t('checkoutPage.thankyou')}</strong> {i18next.t('checkoutPage.thankyou_subtitle')}
                            </div>
                        ) : null}

                        <button
                            onClick={this.createAccount}
                            disabled={!this.isUserReady()}
                            className="btn btn-primary float-right custom-default-red-button"
                            id="roundb"
                        >
                            {i18next.t('signupPage.create_account')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        let token: any = localStorage.getItem("token");
        if(token){
            window.location.href = "/profile";
        }else{
            return (
                <main className="main">
                    <div className="container mt-6">
                        <ul className="checkout-progress-bar">
                            <li
                                id="responsiveTabs"
                                {...((this.props.tabName == "location" ||
                                    this.props.tabName == "loyalityAuth" ||
                                    this.props.tabName == "payment") && {
                                    className: "active",
                                })}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    this.props.history.push({ pathname: "/auth", search: window.location.search })
                                    this.props.setTabName("auth");
                                }}
                            >
                                <span>{i18next.t('checkoutPage.account')}</span>
                            </li>
                        </ul>
                        {this.props.tabName == "loyalityAuth" && this.accountAuth()}
                    </div>
    
                    <div className="mb-6"></div>
                </main>
            );
        }
       
    }
}

const mapStateToProps = (state: any) => {
    return {
        accountAuthStatus: state.login.accountAuthStatus,
        successStatus: state.login.successStatus,
        tabName: state.login.tabName,
        lookupURL: state.login.lookupURL,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setTabName: (tabName: any) => {
            dispatch(setTabName(tabName));
        },
        phoneValidationByOTP: function (phone: any) {
            dispatch(phoneValidationByOTP(phone));
        },
        ValidateOtpForValidation: function (data: any, history: any) {
            dispatch(ValidateOtpForValidation(data, history));
        },
        createAccount: function (data: any, history: any) {
            dispatch(CreateAccount(data, history));
        },
        changeAuthScreen: function () {
            dispatch(changeAuthScreen())
        },
        saveurlMethod: function (url: any) {
            dispatch(saveurlMethod(url));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);